import * as React from 'react';
import { __ } from '../../utilities/common';
import { v1 as uuid } from 'uuid';
import Button from '@sportnet/ui/Button';
import Modal, {
  AlertModal,
  ModalActions,
  ModalContent,
} from '@sportnet/ui/Modal';

// export type WithPopupsProps = {
//   alert: (text: string) => Promise<void>;
//   confirm: (text: string, onConfirm?: () => void) => Promise<boolean>;
// };

const PopupTypes = {
  ALERT: 'ALERT',
  CONFIRM: 'CONFIRM',
};

const makeHoc = (Component) => {
  return class MakeHoc extends React.Component {
    constructor(props) {
      super(props);
      this.promises = {};
      this.state = {
        popups: {},
        isSubmitting: false,
      };
    }

    renderPopup = (popupUid) => {
      const { popups, isSubmitting } = this.state;
      const popup = popups[popupUid];
      const { type } = popup;
      if (type === PopupTypes.ALERT) {
        return (
          <AlertModal
            key={popupUid}
            isOpen
            handleClose={() => {
              this.promises[popupUid].resolve();
              this.close(popupUid);
            }}
          >
            {popup.payload.text}
          </AlertModal>
        );
      }
      if (type === PopupTypes.CONFIRM) {
        return (
          <Modal
            centered
            size="xs"
            key={popupUid}
            isOpen
            handleClose={() => {
              this.promises[popupUid].resolve(false);
              this.close(popupUid);
            }}
          >
            <ModalContent>{popup.payload.text}</ModalContent>
            <ModalActions>
              <Button
                type="button"
                onClick={() => {
                  this.promises[popupUid].resolve(false);
                  this.close(popupUid);
                }}
              >
                {__('Zavrieť')}
              </Button>
              <div>&nbsp;</div>
              <Button
                loading={isSubmitting}
                primary
                type="button"
                onClick={async () => {
                  if (popup.payload.onConfirm) {
                    this.setState({
                      isSubmitting: true,
                    });
                    try {
                      await popup.payload.onConfirm();
                      this.promises[popupUid].resolve(true);
                    } catch (e) {
                      this.promises[popupUid].resolve(false);
                      throw e;
                    }
                    this.close(popupUid);
                    this.setState({
                      isSubmitting: false,
                    });
                  } else {
                    this.promises[popupUid].resolve(true);
                    this.close(popupUid);
                  }
                }}
              >
                {__('Potvrdiť')}
              </Button>
            </ModalActions>
          </Modal>
        );
      }
      return null;
    };

    alert = (text) => {
      const { popups } = this.state;
      return new Promise((resolve) => {
        const uid = uuid();
        this.promises[uid] = { resolve };
        const nextPopups = { ...popups };
        nextPopups[uid] = {
          type: PopupTypes.ALERT,
          payload: {
            text,
          },
        };

        this.setState({
          popups: nextPopups,
        });
      });
    };

    confirm = (text, onConfirm) => {
      const { popups } = this.state;
      return new Promise((resolve) => {
        const uid = uuid();
        this.promises[uid] = { resolve };
        const nextPopups = { ...popups };
        nextPopups[uid] = {
          type: PopupTypes.CONFIRM,
          payload: {
            text,
            onConfirm,
          },
        };

        this.setState({
          popups: nextPopups,
        });
      });
    };

    close(uid) {
      const { popups } = this.state;
      delete this.promises[uid];
      const nextPopups = { ...popups };
      delete nextPopups[uid];
      this.setState({
        popups: nextPopups,
      });
    }

    render() {
      const { popups } = this.state;
      return (
        <>
          <Component
            {...this.props}
            alert={this.alert}
            confirm={this.confirm}
          />
          <>{Object.keys(popups).map(this.renderPopup)}</>
        </>
      );
    }
  };
};

export default makeHoc;
