import { LIST_NAME } from '../Files/constants';
import { __ } from '../../utilities/common';
import {
  addFilesForUpload,
  fetchAppInfo,
  fetchAppSpace,
  loadUser,
  uploadFiles,
} from './actions';
import {
  appIdSelector,
  appSpaceSelector,
  loggedUserSelector,
} from './selectors';
import { connect } from 'react-redux';
import {
  currentFolderIdSelector,
  pathToActiveFolderSelector,
} from '../FolderTree/selectors';
import { filesDraggingSelector } from '../Files/selectors';
import { initialize } from '@sportnet/redux-list';
import { searchFiles } from '../Files/actions';
import Api from '../../Api';
import AppContext from '@sportnet/ui/TheLayout/AppContext';
import ContentLoader from '../../components/ContentLoader';
import Cookies from 'js-cookie';
import Dropzone from '../../components/Dropzone';
import Files from '../Files';
import FolderTree from '../FolderTree';
import Popup from '@sportnet/ui/Popup';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TheLayout from '@sportnet/ui/TheLayout';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex: 1 0 0;
  > div {
    width: 100%;
  }
`;

class App extends Component {
  constructor(props) {
    super(props);

    this.handleOpenMenu = this.handleOpenMenu.bind(this);
    this.handleDropFiles = this.handleDropFiles.bind(this);

    // Initialize redux-list
    props.dispatch(
      initialize({
        listName: LIST_NAME,
        initialParams: {
          offset: 0,
        },
      }),
    );
  }

  async componentDidMount() {
    const { dispatch, alert, appId, appSpace } = this.props;

    dispatch(loadUser());
    dispatch(fetchAppInfo(appId));
    dispatch(fetchAppSpace(appId, appSpace));
    this.setDefaultSidebarState();
    Api.onResponseUnauthorized = () => {
      alert(__(`Na tento obsah nemáte právo!`)).then(() => {
        window.location.href = process.env.REACT_APP_BASE_URL;
      });
    };

    Api.onResponseForbiden = () => {
      alert(__('Na tento obsah nemáte právo'));
    };
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.appId && this.props.appId) {
      this.props.dispatch(fetchAppInfo(this.props.appId));
    }
    if (!prevProps.appSpace && this.props.appSpace) {
      this.props.dispatch(fetchAppSpace(this.props.appId, this.props.appSpace));
    }
  }

  setDefaultSidebarState() {
    const cookie = Cookies.get('sidebar_opened');
    let defaultSidebarState = true;
    if (cookie === '0') {
      defaultSidebarState = false;
    }
    this.setState({
      openedMenu: defaultSidebarState,
    });
  }

  handleOpenMenu() {
    const { openedMenu: openedMenuState } = this.state;
    Cookies.set('sidebar_opened', !openedMenuState ? '1' : '0', {
      expires: 180,
    });
    this.setState({
      openedMenu: !openedMenuState,
    });
  }

  /**
   * @param {File[]} files
   */
  async handleDropFiles(files) {
    const { dispatch, activeFolderId } = this.props;
    dispatch(addFilesForUpload(files, activeFolderId));
    await dispatch(uploadFiles(files));
    dispatch(searchFiles());
  }

  render() {
    const { filesDragging, pathToActiveFolder, appSpace } = this.props;
    const { onClose } = this.context;

    if (typeof appSpace !== 'string') {
      return <ContentLoader />;
    }

    return (
      <Wrapper>
        <TheLayout
          app={this.props.appInfo}
          title="Media Manager"
          customNavigation={<FolderTree />}
          onClose={onClose}
          apps={[]}
          appSpace={this.props.appSpaceUser}
          appSpaces={[]}
        >
          <AppContext
            breadcrumbs={
              pathToActiveFolder.length > 0
                ? [
                    {
                      name: pathToActiveFolder.pop().title,
                    },
                  ]
                : [
                    {
                      name: __('Všetky súbory'),
                    },
                  ]
            }
          />
          <Dropzone
            isActive={!filesDragging}
            onDropFiles={this.handleDropFiles}
          >
            <Files />
          </Dropzone>
        </TheLayout>
      </Wrapper>
    );
  }
}

App.propTypes = {
  alert: PropTypes.func.isRequired,
  user: PropTypes.shape({
    display_name: PropTypes.string,
  }),
  dispatch: PropTypes.func.isRequired,
  filesDragging: PropTypes.bool.isRequired,
  pathToActiveFolder: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string }),
  ).isRequired,
  activeFolderId: PropTypes.number,
};

App.defaultProps = {
  user: null,
  activeFolderId: null,
};

App.contextTypes = {
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    activeFolderId: currentFolderIdSelector(state),
    user: loggedUserSelector(state),
    pathToActiveFolder: pathToActiveFolderSelector(state),
    filesDragging: filesDraggingSelector(state),
    appSpace: appSpaceSelector(state),
    appId: appIdSelector(state),
    appInfo: state.appInfo.data,
    appSpaceUser: state.appSpaceUser.data,
  };
};
const appWithPopup = Popup.withPopups()(App);
export default connect(mapStateToProps)(appWithPopup);
