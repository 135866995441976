import { NumberParam, StringParam } from '@sportnet/query-hoc/useQuery';

const namespace = 'Files';

export const FILE_SELECTION_ADD = `${namespace}/FILE_SELECTION_ADD`;
export const FILE_SELECTION_REMOVE = `${namespace}/FILE_SELECTION_REMOVE`;
export const FILE_SELECTION_CLEAR = `${namespace}/FILE_SELECTION_CLEAR`;
export const DELETE_FILES_REQUEST = `${namespace}/DELETE_FILES_REQUEST`;
export const DELETE_FILES_FAILURE = `${namespace}/DELETE_FILES_FAILURE`;
export const DELETE_FILES_SUCCESS = `${namespace}/DELETE_FILES_SUCCESS`;
export const UPDATE_FILE_ENITIES = `${namespace}/UPDATE_FILE_ENITIES`;
export const MOVE_FILES_REQUEST = `${namespace}/MOVE_FILES_REQUEST`;
export const MOVE_FILES_FAILURE = `${namespace}/MOVE_FILES_FAILURE`;
export const MOVE_FILES_SUCCESS = `${namespace}/MOVE_FILES_SUCCESS`;
export const DRAG_FILE_STARTED = `${namespace}/DRAG_FILE_STARTED`;
export const DRAG_FILE_ENDED = `${namespace}/DRAG_FILE_ENDED`;
export const SET_SEARCH_FILTER = `${namespace}/SET_SEARCH_FILTER`;

export const LIST_NAME = 'file_list';
export const LIST_LIMIT = 200;

export const QUERY_HOC_CONFIG = {
  parameters: {
    offset: NumberParam(0),
    q: StringParam(''),
  },
};
