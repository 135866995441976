/* eslint-disable */
import 'isomorphic-fetch';

class Api {
  constructor() {
    this.baseUrl = null;
    this.token = null;
  }

  serializeQueryParams(parameters) {
    return Object.keys(parameters)
      .reduce((acc, p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  paramsToObject(params) {
    let query = params.substr(1);
    let result = {};
    query.split('&').forEach(function (part) {
      let item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
  }

  mergeQueryParams(parameters, queryParameters) {
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (
        parameterName,
      ) {
        const parameter = parameters.$queryParameters[parameterName];
        queryParameters[parameterName] = parameter;
      });
    }
    return queryParameters;
  }

  transformParameter(value, transformOperation = null) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  /**
   * HTTP Request
   * @method
   * @param {string} method - http method
   * @param {string} url - url to do request
   * @param {object} body - body parameters / object
   * @param {object} headers - header parameters
   * @param {object} queryParameters - querystring parameters
   */
  request(
    method,
    url,
    body,
    headers,
    queryParameters,
    form,
    checkFor401 = true,
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    // ugly hack, we need to delete Content-Type header with multipart/form-data
    // that way, browser will calculate form specific headers on it's own
    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (body && !Object.keys(body).length) {
      body = undefined;
    } else {
      body = JSON.stringify(body);
    }

    if (form && Object.keys(form).length) {
      body = new FormData();
      for (let k in form) {
        body.append(k, form[k]);
      }
    }

    return fetch(urlWithParams, {
      method,
      headers,
      body,
    })
      .then((response) => {
        if (checkFor401) {
          if (response.status === 401) {
            if (typeof this._onResponseUnauthorized === 'function') {
              this._onResponseUnauthorized();
            } else {
              let error = new Error(response.statusText);
              error.response = response;
              throw error;
            }
          }
        }

        if (response.ok) {
          const responseContentType =
            (response.headers && response.headers.get('Content-Type')) || '';
          if (responseContentType.includes('application/json')) {
            return response.json();
          } else if (responseContentType.includes('application/pdf')) {
            return response.blob();
          } else if (responseContentType.includes('text/plain')) {
            return response.text();
          }
          return {};
        } else {
          let error = new Error(response.statusText);
          error.response = response;
          throw error;
        }
      })
      .catch((error) => {
        return error.response.json().then((error_details) => {
          error.details = error_details;
          throw error;
        });
      });
  }

  /**
   * Set base url
   * @method
   * @param {string} baseUrl
   */
  setBaseUrl(baseUrl) {
    this.baseUrl = baseUrl;
  }

  set onResponseUnauthorized(callback) {
    this._onResponseUnauthorized = callback;
  }

  /**
   * Redirects a user to a given url
   * @method
   * @param {string} url
   */
  redirect(url) {
    window.location = url;
  }

  /**
   * Set Token
   * @method
   * @param {string} token - token's value
   */
  setToken(token) {
    this.token = token;
  }

  /**
   * Set Auth headers
   * @method
   * @param {object} headerParams - headers object
   */
  appendAuthHeaders(headerParams) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  /**
     * Endpoint vráti pole objektov obsahujúce priečinky nachádzajúce sa v roote a všetky úrovne podpriečinkov.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appId - RESTful API specification.

       * @param {} appSpace - RESTful API specification.

     */
  getFoldersTree(appId, appSpace, parameters = {}) {
    let path = '/app/{appId}/space/{appSpace}/folders';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre pridanie priečinka do rootu alebo ako podpriečinok (na základe property parent v body requestu).
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appId - RESTful API specification.

       * @param {} appSpace - RESTful API specification.

       * @param {} parameters.body - 
     */
  createFolder(appId, appSpace, parameters = {}) {
    let path = '/app/{appId}/space/{appSpace}/folders';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre získanie detailu priečinka.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appId - RESTful API specification.

       * @param {} appSpace - RESTful API specification.

       * @param {} folderId - RESTful API specification.

     */
  getFolderById(appId, appSpace, folderId, parameters = {}) {
    let path = '/app/{appId}/space/{appSpace}/folders/{folderId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{folderId}', folderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre pridanie priečinka ako podpriečinok.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appId - RESTful API specification.

       * @param {} appSpace - RESTful API specification.

       * @param {} folderId - RESTful API specification.

       * @param {} parameters.body - 
     */
  createSubfolder(appId, appSpace, folderId, parameters = {}) {
    let path = '/app/{appId}/space/{appSpace}/folders/{folderId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{folderId}', folderId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre update priečinku. Zmenia sa len tie údaje, ktoré sú zaslané. Povolené atribúty budú špecifikované v rámci schémy.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appId - RESTful API specification.

       * @param {} appSpace - RESTful API specification.

       * @param {} folderId - RESTful API specification.

       * @param {} parameters.body - 
     */
  editFolderById(appId, appSpace, folderId, parameters = {}) {
    let path = '/app/{appId}/space/{appSpace}/folders/{folderId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{folderId}', folderId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Zmazanie priečinku a presunutie jeho súborov na úroveň priečinku, kde sa nachádzal tento priečinok.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appId - RESTful API specification.

       * @param {} appSpace - RESTful API specification.

       * @param {} folderId - RESTful API specification.

     */
  deleteFolderById(appId, appSpace, folderId, parameters = {}) {
    let path = '/app/{appId}/space/{appSpace}/folders/{folderId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{folderId}', folderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Presunutie priečinka do iného priečinka alebo rootu formou zmeny poľa parentov v tomto priečinku a vo všetkých jeho podpriečinkoch, rovnako ako zmena poľa parentov pre všetky podradené súbory.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appId - RESTful API specification.

       * @param {} appSpace - RESTful API specification.

       * @param {} folderId - RESTful API specification.

       * @param {} destinationId - RESTful API specification.

     */
  moveFolderById(appId, appSpace, folderId, destinationId, parameters = {}) {
    let path =
      '/app/{appId}/space/{appSpace}/folders/{folderId}/move/{destinationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{folderId}', folderId);

    path = path.replace('{destinationId}', destinationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre stiahnutie súboru a ulozenie do cieľového adresára zadefinovaného ako parameter destination (nepovinné)
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appId - RESTful API specification.

       * @param {} appSpace - RESTful API specification.

       * @param {} parameters.body - 
     */
  createFileFromUrl(appId, appSpace, parameters = {}) {
    let path = '/app/{appId}/space/{appSpace}/filefromurl';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre získanie zoznamu súborov pre všetky adresáre
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.parentId - RESTful API specification.

       * @param {} parameters.name - RESTful API specification.

       * @param {} parameters.source - RESTful API specification.

       * @param {} parameters.type - RESTful API specification.

       * @param {} parameters.smarttag - RESTful API specification.

       * @param {} parameters.query - RESTful API specification.

       * @param {} parameters.sorter - RESTful API specification.

       * @param {} parameters.offset - RESTful API specification.

       * @param {} parameters.limit - RESTful API specification.

       * @param {} parameters.showTemporaryFiles - RESTful API specification.

       * @param {} appId - RESTful API specification.

       * @param {} appSpace - RESTful API specification.

     */
  getFiles(appId, appSpace, parameters = {}) {
    let path = '/app/{appId}/space/{appSpace}/files';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['parentId'] !== 'undefined') {
      queryParameters['parent_id'] = parameters['parentId'];
    }

    queryParameters['parent_id'] = this.transformParameter(
      queryParameters['parent_id'],
    );

    if (typeof parameters['name'] !== 'undefined') {
      queryParameters['name'] = parameters['name'];
    }

    queryParameters['name'] = this.transformParameter(queryParameters['name']);

    if (typeof parameters['source'] !== 'undefined') {
      queryParameters['source'] = parameters['source'];
    }

    queryParameters['source'] = this.transformParameter(
      queryParameters['source'],
    );

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['smarttag'] !== 'undefined') {
      queryParameters['smarttag'] = parameters['smarttag'];
    }

    queryParameters['smarttag'] = this.transformParameter(
      queryParameters['smarttag'],
      'joinUsingPipes',
    );

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    queryParameters['sorter'] = '-created_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 50;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    if (typeof parameters['showTemporaryFiles'] !== 'undefined') {
      queryParameters['showTemporaryFiles'] = parameters['showTemporaryFiles'];
    }

    queryParameters['showTemporaryFiles'] = this.transformParameter(
      queryParameters['showTemporaryFiles'],
    );

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre upload súboru do cieľového adresára zadefinovaného ako parameter destination (nepovinné)
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appId - RESTful API specification.

       * @param {} appSpace - RESTful API specification.

       * @param {} parameters.body - 
     */
  uploadFile(appId, appSpace, parameters = {}) {
    let path = '/app/{appId}/space/{appSpace}/files';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    if (typeof parameters['body'] !== 'undefined') {
      form = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre úpravu informácií o viacerých súboroch podľa ID súborov
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appId - RESTful API specification.

       * @param {} appSpace - RESTful API specification.

       * @param {} parameters.body - 
     */
  editFilesByIds(appId, appSpace, parameters = {}) {
    let path = '/app/{appId}/space/{appSpace}/files';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre zmazanie viacerých súborov podľa ID
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appId - RESTful API specification.

       * @param {} appSpace - RESTful API specification.

       * @param {} parameters.body - 
     */
  deleteFilesByIds(appId, appSpace, parameters = {}) {
    let path = '/app/{appId}/space/{appSpace}/files';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre hromadný presun súborov do jedného priečinku
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appId - RESTful API specification.

       * @param {} appSpace - RESTful API specification.

       * @param {} parameters.body - 
     */
  moveFiles(appId, appSpace, parameters = {}) {
    let path = '/app/{appId}/space/{appSpace}/files/move';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre detail súboru na základe jeho ID
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appId - RESTful API specification.

       * @param {} appSpace - RESTful API specification.

       * @param {} fileId - RESTful API specification.

     */
  getFileById(appId, appSpace, fileId, parameters = {}) {
    let path = '/app/{appId}/space/{appSpace}/file/{fileId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{fileId}', fileId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre úpravu informácií o súbore podľa ID súboru
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appId - RESTful API specification.

       * @param {} appSpace - RESTful API specification.

       * @param {} fileId - RESTful API specification.

       * @param {} parameters.body - 
     */
  editFileById(appId, appSpace, fileId, parameters = {}) {
    let path = '/app/{appId}/space/{appSpace}/file/{fileId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{fileId}', fileId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre zmazanie súboru podľa ID
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appId - RESTful API specification.

       * @param {} appSpace - RESTful API specification.

       * @param {} fileId - RESTful API specification.

     */
  deleteFileById(appId, appSpace, fileId, parameters = {}) {
    let path = '/app/{appId}/space/{appSpace}/file/{fileId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{fileId}', fileId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre presun súboru do priečinku
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appId - RESTful API specification.

       * @param {} appSpace - RESTful API specification.

       * @param {} fileId - RESTful API specification.

       * @param {} destinationId - RESTful API specification.

     */
  moveFile(appId, appSpace, fileId, destinationId, parameters = {}) {
    let path =
      '/app/{appId}/space/{appSpace}/file/{fileId}/move/{destinationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    path = path.replace('{fileId}', fileId);

    path = path.replace('{destinationId}', destinationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre získanie zoznamu smarttagov
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} appId - RESTful API specification.

       * @param {} appSpace - RESTful API specification.

     */
  getSmartTags(appId, appSpace, parameters = {}) {
    let path = '/app/{appId}/space/{appSpace}/smarttags';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{appId}', appId);

    path = path.replace('{appSpace}', appSpace);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Endpoint vráti pole objektov obsahujúce priečinky nachádzajúce sa v roote a všetky úrovne podpriečinkov.
   * @method
   * @param {object} parameters - method options and parameters
   */
  getUserFoldersTree(parameters = {}) {
    let path = '/user/folders';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Endpoint pre pridanie priečinka do rootu alebo ako podpriečinok (na základe property parent v body requestu).
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body -
   */
  createUserFolder(parameters = {}) {
    let path = '/user/folders';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre získanie detailu priečinka.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} folderId - RESTful API specification.

     */
  getUserFolderById(folderId, parameters = {}) {
    let path = '/user/folders/{folderId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{folderId}', folderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre pridanie priečinka ako podpriečinok.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} folderId - RESTful API specification.

       * @param {} parameters.body - 
     */
  createUserSubfolder(folderId, parameters = {}) {
    let path = '/user/folders/{folderId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{folderId}', folderId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre update priečinku. Zmenia sa len tie údaje, ktoré sú zaslané. Povolené atribúty budú špecifikované v rámci schémy.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} folderId - RESTful API specification.

       * @param {} parameters.body - 
     */
  editUserFolderById(folderId, parameters = {}) {
    let path = '/user/folders/{folderId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{folderId}', folderId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Zmazanie priečinku a presunutie jeho súborov na úroveň priečinku, kde sa nachádzal tento priečinok.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} folderId - RESTful API specification.

     */
  deleteUserFolderById(folderId, parameters = {}) {
    let path = '/user/folders/{folderId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{folderId}', folderId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Presunutie priečinka do iného priečinka alebo rootu formou zmeny poľa parentov v tomto priečinku a vo všetkých jeho podpriečinkoch, rovnako ako zmena poľa parentov pre všetky podradené súbory.
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} folderId - RESTful API specification.

       * @param {} destinationId - RESTful API specification.

     */
  moveUserFolderById(folderId, destinationId, parameters = {}) {
    let path = '/user/folders/{folderId}/move/{destinationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{folderId}', folderId);

    path = path.replace('{destinationId}', destinationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Endpoint pre stiahnutie súboru a ulozenie do cieľového adresára zadefinovaného ako parameter destination (nepovinné)
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body -
   */
  createUserFileFromUrl(parameters = {}) {
    let path = '/user/filefromurl';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre získanie zoznamu súborov pre všetky adresáre
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} parameters.parentId - RESTful API specification.

       * @param {} parameters.name - RESTful API specification.

       * @param {} parameters.source - RESTful API specification.

       * @param {} parameters.type - RESTful API specification.

       * @param {} parameters.smarttag - RESTful API specification.

       * @param {} parameters.query - RESTful API specification.

       * @param {} parameters.sorter - RESTful API specification.

       * @param {} parameters.offset - RESTful API specification.

       * @param {} parameters.limit - RESTful API specification.

     */
  getUserFiles(parameters = {}) {
    let path = '/user/files';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    if (typeof parameters['parentId'] !== 'undefined') {
      queryParameters['parent_id'] = parameters['parentId'];
    }

    queryParameters['parent_id'] = this.transformParameter(
      queryParameters['parent_id'],
    );

    if (typeof parameters['name'] !== 'undefined') {
      queryParameters['name'] = parameters['name'];
    }

    queryParameters['name'] = this.transformParameter(queryParameters['name']);

    if (typeof parameters['source'] !== 'undefined') {
      queryParameters['source'] = parameters['source'];
    }

    queryParameters['source'] = this.transformParameter(
      queryParameters['source'],
    );

    if (typeof parameters['type'] !== 'undefined') {
      queryParameters['type'] = parameters['type'];
    }

    queryParameters['type'] = this.transformParameter(queryParameters['type']);

    if (typeof parameters['smarttag'] !== 'undefined') {
      queryParameters['smarttag'] = parameters['smarttag'];
    }

    queryParameters['smarttag'] = this.transformParameter(
      queryParameters['smarttag'],
      'joinUsingPipes',
    );

    if (typeof parameters['query'] !== 'undefined') {
      queryParameters['query'] = parameters['query'];
    }

    queryParameters['query'] = this.transformParameter(
      queryParameters['query'],
    );

    queryParameters['sorter'] = '-created_date';

    if (typeof parameters['sorter'] !== 'undefined') {
      queryParameters['sorter'] = parameters['sorter'];
    }

    queryParameters['sorter'] = this.transformParameter(
      queryParameters['sorter'],
    );

    if (typeof parameters['offset'] !== 'undefined') {
      queryParameters['offset'] = parameters['offset'];
    }

    queryParameters['offset'] = this.transformParameter(
      queryParameters['offset'],
    );

    queryParameters['limit'] = 50;

    if (typeof parameters['limit'] !== 'undefined') {
      queryParameters['limit'] = parameters['limit'];
    }

    queryParameters['limit'] = this.transformParameter(
      queryParameters['limit'],
    );

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Endpoint pre upload súboru do cieľového adresára zadefinovaného ako parameter destination (nepovinné)
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body -
   */
  uploadUserFile(parameters = {}) {
    let path = '/user/files';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      form = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Endpoint pre úpravu informácií o viacerých súboroch podľa ID súborov
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body -
   */
  editUserFilesByIds(parameters = {}) {
    let path = '/user/files';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Endpoint pre zmazanie viacerých súborov podľa ID
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body -
   */
  deleteUserFilesByIds(parameters = {}) {
    let path = '/user/files';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
   * Endpoint pre hromadný presun súborov do jedného priečinku
   * @method
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body -
   */
  moveUserFiles(parameters = {}) {
    let path = '/user/files/move';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre detail súboru na základe jeho ID
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} fileId - RESTful API specification.

     */
  getUserFileById(fileId, parameters = {}) {
    let path = '/user/file/{fileId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{fileId}', fileId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre úpravu informácií o súbore podľa ID súboru
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} fileId - RESTful API specification.

       * @param {} parameters.body - 
     */
  editUserFileById(fileId, parameters = {}) {
    let path = '/user/file/{fileId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    path = path.replace('{fileId}', fileId);

    if (typeof parameters['body'] !== 'undefined') {
      body = parameters['body'];
    }

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre zmazanie súboru podľa ID
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} fileId - RESTful API specification.

     */
  deleteUserFileById(fileId, parameters = {}) {
    let path = '/user/file/{fileId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{fileId}', fileId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
  /**
     * Endpoint pre presun súboru do priečinku
     * @method
     * @param {object} parameters - method options and parameters
       * @param {} fileId - RESTful API specification.

       * @param {} destinationId - RESTful API specification.

     */
  moveUserFile(fileId, destinationId, parameters = {}) {
    let path = '/user/file/{fileId}/move/{destinationId}';
    let body = {};
    let queryParameters = {};
    let headers = new Headers();
    let form = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');

    path = path.replace('{fileId}', fileId);

    path = path.replace('{destinationId}', destinationId);

    queryParameters = this.mergeQueryParams(parameters, queryParameters);

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
      form,
    );
  }
}

export default new Api();
export { Api };
