import { __ } from '../../utilities/common';
import { rem, rgba } from 'polished';
import Icon from '@sportnet/ui/Icon';
import React, { Component } from 'react';
import styled, { ThemeProvider, keyframes } from 'styled-components';

const pulseAnimation = keyframes`
  0% { opacity: 1 }
  50% { opacity: 0.9 }
  100% { opacity: 1 }
`;

const pulseScaleAnimation = keyframes`
  0% { transform: scale(1) }
  50% { transform: scale(1.02) }
  100% { transform: scale(1) }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => rgba(theme.color.primary, 0.9)};
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${pulseAnimation} 2s ease-in-out infinite;
`;

const Window = styled.div`
  padding: ${rem(60)} ${rem(120)};
  border-radius: ${({ theme }) => theme.largeBorderRadius};
  border: ${rem(3)} dashed ${({ theme }) => theme.textColor};
  text-align: center;
  color: ${({ theme }) => theme.textColor};
  animation: ${pulseScaleAnimation} 2s ease-in-out infinite;
`;

const Title = styled.div`
  font-size: ${rem(32)};
  font-weight: 500;
  margin-top: ${rem(14)};
`;

const Subtitle = styled.div`
  font-size: ${rem(16)};
  margin-top: ${rem(13)};
`;

class Overlay extends Component {
  render() {
    return (
      <ThemeProvider
        theme={{
          textColor: '#fff',
        }}
      >
        <Wrapper>
          <Window>
            <Icon name="file-download" />
            <Title>Drag &amp; Drop</Title>
            <Subtitle>
              {__('Sem vložte svoje súbory a zahájite nahrávanie.')}
            </Subtitle>
          </Window>
        </Wrapper>
      </ThemeProvider>
    );
  }
}

export default Overlay;
