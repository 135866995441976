import {
  EDIT_MULTIPLE_FILES_FAILURE,
  EDIT_MULTIPLE_FILES_REQUEST,
  EDIT_MULTIPLE_FILES_SUCCESS,
} from './constants';
import { appIdSelector, appSpaceSelector } from '../App/selectors';
import { updateFileEntities } from '../Files/actions';
import Api from '../../Api';

/**
 *
 * @param {Object[]} files Array of objects. Each object must have _id and those fields which wants to be changes (example: { _id: 1, name: 'new name' })
 */
export const editMultipleFilesRequest = (files) => ({
  type: EDIT_MULTIPLE_FILES_REQUEST,
  payload: {
    files,
  },
});

/**
 *
 * @param {Object[]} files Array of objects that contains EDITED files
 */
export const editMultipleFilesSuccess = (editedFiles) => ({
  type: EDIT_MULTIPLE_FILES_SUCCESS,
  payload: {
    editedFiles,
  },
});

/**
 *
 * @param {File} file
 * @param {*} error
 */
export const editMultipleFilesFailure = () => ({
  type: EDIT_MULTIPLE_FILES_FAILURE,
});

export const editFiles = (files) => {
  return async (dispatch, getState) => {
    if (files.length === 0) {
      return;
    }
    dispatch(editMultipleFilesRequest(files));
    try {
      let response;
      const appSpace = appSpaceSelector(getState());
      const data = {
        appSpace: appSpaceSelector(getState()),
        body: {
          id: files.filter((file) => file._id).map((file) => file._id),
          author: files[0].author,
          name: files[0].name,
          description: files[0].description,
          source: files[0].source,
          smarttags: files[0].smarttags,
          expiresAt: files[0].expiresAt,
        },
      };
      if (appSpace) {
        response = await Api.editFilesByIds(
          appIdSelector(getState()),
          appSpace,
          data,
        );
      } else {
        response = await Api.editUserFilesByIds(data);
      }

      dispatch(updateFileEntities(response.files));
      dispatch(editMultipleFilesSuccess(response.files));
      return response.files;
    } catch (e) {
      dispatch(editMultipleFilesFailure());
    }
  };
};
