import { __ } from '../../utilities/common';
import { connect } from 'react-redux';
import { rem } from 'polished';
import { uploadingFilesUploadingStatusSelector } from '../App/selectors';
import Loader from '@sportnet/ui/Loader';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${rem(14)};
`;

const StyledLoader = styled(Loader)`
  margin-right: ${rem(10)};
`;

const Loading = styled.div`
  display: flex;
`;

class UploadStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timerElapsed: false,
    };
    this.timerElapsedTimeout = null;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.uploadStatus !== prevProps.uploadStatus &&
      this.props.uploadStatus === 1
    ) {
      this.timerElapsedTimeout = setTimeout(() => {
        this.setState({ timerElapsed: true });
      }, 5000);
    } else if (
      this.props.uploadStatus !== prevProps.uploadStatus &&
      this.props.uploadStatus !== 1
    ) {
      clearTimeout(this.timerElapsedTimeout);
      this.setState({ timerElapsed: false });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timerElapsedTimeout);
  }

  render() {
    const { uploadStatus, className } = this.props;
    const { timerElapsed } = this.state;
    if (uploadStatus === null || timerElapsed) {
      return null;
    }
    return (
      <Wrapper className={className}>
        {uploadStatus === 1 ? (
          __('Všetky súbory nahraté')
        ) : (
          <Loading>
            <StyledLoader />
            {__('Nahrávanie')}
          </Loading>
        )}
      </Wrapper>
    );
  }
}

UploadStatus.propTypes = {
  className: PropTypes.string,
  uploadStatus: PropTypes.number,
};

UploadStatus.defaultProps = {
  className: null,
  uploadStatus: null,
};

const mapStateToProps = (state) => {
  return {
    uploadStatus: uploadingFilesUploadingStatusSelector(state),
  };
};

export default connect(mapStateToProps)(UploadStatus);
