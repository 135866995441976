import { createSelector } from 'reselect';

const folderTreeDomainSelector = (state) => state.folderTree;

export const foldersSelector = createSelector(
  folderTreeDomainSelector,
  (data) => {
    return data.folders || [];
  },
);

export const currentFolderIdSelector = createSelector(
  folderTreeDomainSelector,
  (substate) => substate.activeId,
);

export const foldersByIdSelector = createSelector(
  foldersSelector,
  (folders) => {
    const traverseChildren = (children) => {
      let result = {};
      children.forEach((folder) => {
        result[folder._id] = {
          _id: folder._id,
          title: folder.title,
          expanded: folder.expanded || false,
          parents: folder.parents || [],
        };
        if (folder.children) {
          result = { ...result, ...traverseChildren(folder.children) };
        }
      });
      return result;
    };

    return traverseChildren(folders);
  },
);

export const pathToActiveFolderSelector = createSelector(
  foldersByIdSelector,
  currentFolderIdSelector,
  (foldersById, activeFolderId) => {
    const currentFolder = foldersById[activeFolderId];
    if (currentFolder) {
      const path = currentFolder.parents.map((parentId) => {
        return parentId in foldersById ? foldersById[parentId] : null;
      });
      return [...path, currentFolder]; // pripojime aktualne vybratu polozku na koniec
    }
    return [];
  },
);
