import { ADD_TOAST, REMOVE_TOAST } from './constants';

const toastsInitialState = {
  items: [],
};

const toasts = (state = toastsInitialState, action) => {
  switch (action.type) {
    case ADD_TOAST: {
      return {
        items: [action.payload, ...state.items],
      };
    }
    case REMOVE_TOAST: {
      return {
        items: state.items.filter((item) => item.id !== action.payload.id),
      };
    }
    default: {
      return state;
    }
  }
};

export default toasts;
