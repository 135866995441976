export const processDroppedFile = (ev) => {
  ev.preventDefault();
  const jsonData = ev.dataTransfer.getData('text');
  try {
    const data = JSON.parse(jsonData);
    if (data._id) {
      return data._id;
    }
    return null;
  } catch (e) {
    return null;
  }
};
