import { __ } from '../../utilities/common';
import { appSpaceSelector } from '../App/selectors';
import { caseInsensitiveSearchMethod } from './theme/search-method';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  createFolder,
  fetchFolderTree,
  removeFolder,
  renameFolder,
  setActiveFolder,
  setFolderTree,
} from './actions';
import { currentFolderIdSelector, foldersSelector } from './selectors';
import { moveFiles, searchFiles } from '../Files/actions';
import { processDroppedFile } from './utilities';
import { rem } from 'polished';
import LayoutContext from '@sportnet/ui/TheLayout/LayoutContext';
import Prompt from '../../components/Prompt';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import SortableTree from 'react-sortable-tree';
import styled, { css } from 'styled-components';
import treeTheme from './theme';
import withPopups from '../../components/WithPopups';

const Button = styled.button`
  background: transparent;
  border: none;
  visibility: hidden;
  cursor: pointer;
  padding: 0;
`;
const Item = styled.div`
  font-size: ${rem(12)};
  font-weight: bold;
  padding: ${rem(15)} ${rem(10)} ${rem(10)} ${rem(10)};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  &:hover button {
    visibility: visible;
  }
  @media (pointer: coarse) {
    ${Button} {
      visibility: visible;
    }
  }
  ${({ active, theme }) =>
    active &&
    css`
      color: ${theme.color.primary};
    `};
  ${({ theme, hovering }) =>
    hovering &&
    css`
      border: 2px dotted ${theme.color.primary};
    `};
`;

const NoContent = styled.div`
  display: flex;
  flex: 1 0 0;
  align-items: center;
  justify-items: center;
  text-align: center;
  color: ${({ theme }) => theme.baseColor};

  > div {
    cursor: pointer;
    padding: 1rem 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
`;

// const Input = styled.input`
//   width: 100%;
//   padding: ${rem(10.42)};
//   margin-bottom: ${rem(15)};
//   background: transparent;
//   border: 0;
//   border-bottom: ${rem(1)} solid ${({ theme }) => theme.separatorColor};

//   &::placeholder {
//     color: #bbb;
//     font-size: ${rem(14)};
//   }

//   &:focus {
//     outline: none;
//   }
// `;

class FolderTree extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      hoveringRoot: 0,
      prompt: undefined,
    };
    this.onChangeTree = this.onChangeTree.bind(this);
    this.handleRootFolderClick = this.handleRootFolderClick.bind(this);
    this.handleClickNode = this.handleClickNode.bind(this);
    this.handleDropFile = this.handleDropFile.bind(this);
    this.handleCreateFolder = this.handleCreateFolder.bind(this);
    this.handleRenameFolder = this.handleRenameFolder.bind(this);
    this.handleRemoveFolder = this.handleRemoveFolder.bind(this);
    this.handleChangeSearchQuery = this.handleChangeSearchQuery.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchFolderTree());
  }

  componentDidUpdate(prevProps) {
    if (this.props.appSpace && this.props.appSpace !== prevProps.appSpace) {
      const { dispatch } = this.props;
      dispatch(fetchFolderTree());
    }
  }

  onChangeTree(treeData) {
    const { dispatch } = this.props;
    dispatch(setFolderTree(treeData));
  }

  handleClickNode(node) {
    const { dispatch } = this.props;
    const { closeNavigationMobile } = this.context;
    dispatch(setActiveFolder(node._id));
    closeNavigationMobile();
  }

  async handleDropFile(folderId, fileId) {
    const { dispatch, alert } = this.props;
    try {
      await dispatch(moveFiles(folderId, fileId));
      dispatch(searchFiles());
    } catch (e) {
      alert(__(`Pri presune súborov nastala chyba!`));
    }
  }

  handleRootFolderClick() {
    const { dispatch } = this.props;
    const { closeNavigationMobile } = this.context;
    this.setState({ query: null });
    const folderId = null;
    dispatch(setActiveFolder(folderId));
    closeNavigationMobile();
  }

  handleChangeSearchQuery(event) {
    const query = event.target.value;
    this.setState({ query });
  }

  async handleCreateFolder(node) {
    const { dispatch } = this.props;
    let name = await new Promise((resolve, reject) => {
      this.setState({ prompt: ['Názov adresára', '', resolve, reject] });
    }).then((result) => {
      this.setState({ prompt: undefined });
      return result;
    });
    name = name ? name.trim() : '';
    if (name) {
      await dispatch(createFolder(node._id, name));
      dispatch(fetchFolderTree());
    }
  }

  async handleRenameFolder(node) {
    const { dispatch } = this.props;
    let name = await new Promise((resolve, reject) => {
      this.setState({
        prompt: ['Nový názov adresára', node.title, resolve, reject],
      });
    }).then((result) => {
      this.setState({ prompt: undefined });
      return result;
    });
    name = name ? name.trim() : '';
    if (name) {
      await dispatch(renameFolder(node._id, name));
      dispatch(fetchFolderTree());
    }
  }

  async handleRemoveFolder(node) {
    const { dispatch, confirm } = this.props;
    if (
      await confirm(
        __(
          `Naozaj chcete odstrániť adresár "${node.title}" a všetky jeho podadresáre?`,
        ),
      )
    ) {
      await dispatch(removeFolder(node._id));
      dispatch(fetchFolderTree());
    }
  }

  render() {
    const { activeId, folderTree } = this.props;
    const { query, hoveringRoot } = this.state;
    return (
      <Wrapper>
        {/* <Input
          type="text"
          placeholder={__('Vyhľadávanie v priečinkoch')}
          onChange={this.handleChangeSearchQuery}
        /> */}
        <Item
          hovering={hoveringRoot > 0}
          onDragEnter={(ev) => {
            ev.preventDefault();
            this.setState({ hoveringRoot: hoveringRoot + 1 });
          }}
          onDragLeave={(ev) => {
            ev.preventDefault();
            this.setState({ hoveringRoot: hoveringRoot - 1 });
          }}
          onClick={this.handleRootFolderClick}
          active={!activeId}
          onDrop={(ev) => {
            this.setState({ hoveringRoot: 0 });
            const id = processDroppedFile(ev);
            if (id === null) {
              console.error('Failed to parse json');
            } else {
              this.handleDropFile(null, id);
            }
          }}
          onDragOver={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
          }}
        >
          {__('Všetky súbory')}
          <Button
            onClick={() => {
              this.handleCreateFolder({
                _id: null,
              });
            }}
            data-testid="MM_FolderTree_Icon_AddRoot"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 8 8"
              width="12"
              height="12"
            >
              <path
                fill="#555555"
                fillRule="evenodd"
                d="M8 5H5v3H3V5H-.001V3H3V0h2v3h3v2z"
              />
            </svg>
          </Button>
        </Item>

        {folderTree.length > 0 && (
          <SortableTree
            isVirtualized={false}
            searchMethod={caseInsensitiveSearchMethod}
            searchQuery={query}
            expandOnlySearchedNodes
            canDrag={false}
            treeData={folderTree}
            onChange={this.onChangeTree}
            generateNodeProps={() => ({
              onClickNodeTitle: this.handleClickNode,
              onDropFile: this.handleDropFile,
              activeNodeId: activeId,
              buttons: [
                {
                  icon: 'plus',
                  onClick: this.handleCreateFolder,
                },
                {
                  icon: 'pencil',
                  onClick: this.handleRenameFolder,
                },
                {
                  icon: 'trash',
                  onClick: this.handleRemoveFolder,
                },
              ],
            })}
            theme={treeTheme}
          />
        )}

        {folderTree.length < 1 && !query && (
          <NoContent>
            <div onClick={() => this.handleCreateFolder({ _id: null })}>
              {__('Žiadne adresáre. Kliknutím tu vytvorte prvý adresár')}
            </div>
          </NoContent>
        )}

        {this.state.prompt && (
          <Prompt
            promptTitle={this.state.prompt[0]}
            promptValue={this.state.prompt[1]}
            promptResolve={this.state.prompt[2]}
          />
        )}
      </Wrapper>
    );
  }
}

FolderTree.propTypes = {
  dispatch: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  alert: PropTypes.func.isRequired,
  folderTree: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  activeId: PropTypes.string,
};

FolderTree.defaultProps = {
  activeId: null,
};

const mapStateToProps = (state) => {
  return {
    folderTree: foldersSelector(state),
    activeId: currentFolderIdSelector(state),
    appSpace: appSpaceSelector(state),
  };
};

FolderTree.contextType = LayoutContext;

export default compose(withPopups, connect(mapStateToProps))(FolderTree);
