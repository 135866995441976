import { rem } from 'polished';
import Overlay from './Overlay';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  flex: 1 0 0;
  display: flex;
  height: calc(100vh - ${rem(48)});
`;

class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovering: 0, // this is number because of how onDragEnter and onDragLeave works
    };
    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }

  handleDragEnter(e) {
    const { isActive } = this.props;
    const { hovering } = this.state;
    if (isActive) {
      e.preventDefault();
      this.setState({ hovering: hovering + 1 });
    }
  }

  handleDragOver(e) {
    const { isActive } = this.props;
    if (isActive) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  handleDragLeave(e) {
    const { isActive } = this.props;
    const { hovering } = this.state;
    if (isActive) {
      e.preventDefault();
      this.setState({ hovering: hovering - 1 });
    }
  }

  handleDrop(e) {
    const { isActive, onDropFiles } = this.props;
    if (isActive) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ hovering: 0 });

      const droppedFiles = e.dataTransfer
        ? e.dataTransfer.files
        : e.target.files;
      const files = [];
      for (let i = 0; i < droppedFiles.length; i++) {
        const file = droppedFiles[i];
        files.push(file);
      }

      onDropFiles(files);
    }
  }

  render() {
    const { children } = this.props;
    const { hovering } = this.state;
    return (
      <Wrapper
        onDragEnter={this.handleDragEnter}
        onDragOver={this.handleDragOver}
        onDragLeave={this.handleDragLeave}
        onDrop={this.handleDrop}
        data-testid="MM_App_DropZone"
      >
        {children}
        {hovering > 0 && <Overlay />}
      </Wrapper>
    );
  }
}

Dropzone.propTypes = {
  children: PropTypes.node,
  onDropFiles: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

Dropzone.defaultProps = {
  children: null,
};

export default Dropzone;
