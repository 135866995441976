import * as actions from '../Files/actions';
import { Field, change, reduxForm } from 'redux-form';
import { __ } from '../../utilities/common';
import {
  accessTokenSelector,
  appIdSelector,
  appSpaceSelector,
  onSelectFilesFnAvailableSelector,
} from '../App/selectors';
import {
  allFilesInSelectionSelector,
  selectedFilesIdsSelector,
} from '../Files/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getProp } from '@sportnet/utilities';
import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField/redux-form';
import FormGroup from '@sportnet/ui/FormGroup';
import Label from '@sportnet/ui/Label/Label';
import PropTypes from 'prop-types';
import React from 'react';
import SmarttagControl from '@sportnet/ui/SmarttagsControl/SmarttagControl/redux-form';
import TagmanagerConnector from '@sportnet/tagmanager-connector';
import styled from 'styled-components';
import withPopups from '../../components/WithPopups';

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FileForm = (props) => {
  const {
    handleSubmit,
    cancelButtonClick,
    submitting,
    appSpace,
    accessToken,
    appId,
    confirm,
    deleteFiles,
    searchFiles,
    selectedFiles,
    selectedFilesIds,
    onSelectFilesFnAvailable,
    dispatch,
    emptyFileSelection,
  } = props;

  async function handleDeleteSelectedFiles() {
    if (
      await confirm(
        __(
          `Naozaj chcete odstrániť všetky vybrané (${selectedFiles.length}) súbory?`,
        ),
      )
    ) {
      await deleteFiles(selectedFilesIds);
      searchFiles();
      emptyFileSelection();
    }
  }

  const editName = getProp(props, ['valuesSelected', 'editName'], false);
  const editExpiresAt = getProp(
    props,
    ['valuesSelected', 'editExpiresAt'],
    false,
  );
  const editAuthor = getProp(props, ['valuesSelected', 'editAuthor'], false);
  const editDescription = getProp(
    props,
    ['valuesSelected', 'editDescription'],
    false,
  );
  const editSource = getProp(props, ['valuesSelected', 'editSource'], false);
  const editSmartTags = getProp(
    props,
    ['valuesSelected', 'editSmartTags'],
    false,
  );
  const isSomethingSelected =
    editExpiresAt ||
    editName ||
    editAuthor ||
    editDescription ||
    editSmartTags ||
    editSource;
  const handleSaveOnly = () => {
    dispatch(change(props.form, 'saveOnly', true));
  };
  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      onReset={cancelButtonClick}
    >
      <Field
        label={__('Upraviť platnosť súboru')}
        name="editExpiresAt"
        type="checkbox"
        component={FormField}
      />
      {editExpiresAt && (
        <Field
          name="expiresAt"
          component={FormField}
          label={__('Platnosť súboru')}
          placeholder={__('Zadajte dátum platnosti')}
          type="dateTime"
        />
      )}
      <Field
        label={__('Upraviť názov súboru')}
        name="editName"
        type="checkbox"
        component={FormField}
      />
      {editName && (
        <Field
          name="name"
          component={FormField}
          label={__('Názov súboru')}
          placeholder={__('Zadajte názov')}
        />
      )}
      <Field
        name="editAuthor"
        label={__('Upraviť autora')}
        component={FormField}
        type="checkbox"
      />
      {editAuthor && (
        <Field
          name="author"
          label={__('Autor')}
          component={FormField}
          placeholder={__('Zadajte meno autora')}
        />
      )}
      <Field
        label={__('Upraviť popis')}
        name="editDescription"
        component={FormField}
        type="checkbox"
      />
      {editDescription && (
        <Field
          name="description"
          component={FormField}
          type="textarea"
          label={__('Popis')}
          placeholder={__('Zadajte popis')}
        />
      )}
      <Field
        name="editSource"
        component={FormField}
        type="checkbox"
        label={__('Upraviť zdroj')}
      />
      {editSource && (
        <Field
          name="source"
          label={__('Zdroj')}
          component={FormField}
          placeholder={__('Zadajte zdroj')}
        />
      )}
      {appSpace && (
        <Field
          name="editSmartTags"
          component={FormField}
          type="checkbox"
          label={__('Upraviť smart tagy')}
        />
      )}
      {editSmartTags && appSpace && (
        <FormGroup>
          <Label>{__('Smart:tag')}</Label>
          <TagmanagerConnector
            appId={appId}
            appspace={appSpace}
            accessToken={accessToken}
          >
            {(getSmarttagsKeys, getSmarttagsValues, getSmarttagsByFts) => (
              <Field
                getSmarttagsValues={getSmarttagsValues}
                getSmarttagsKeys={getSmarttagsKeys}
                getSmarttagsByFts={getSmarttagsByFts}
                name="smarttags"
                component={SmarttagControl}
              />
            )}
          </TagmanagerConnector>
        </FormGroup>
      )}
      <Buttons>
        <Button
          danger
          basic
          onClick={handleDeleteSelectedFiles}
          type="button"
          icon="trash"
        ></Button>
        <Button
          disabled={!isSomethingSelected}
          loading={submitting}
          primary
          type="submit"
          onClick={() => {
            handleSaveOnly();
          }}
        >
          {__(`Uložiť (${selectedFiles.length})`)}
        </Button>
      </Buttons>
      {!!onSelectFilesFnAvailable && (
        <>
          <br />
          <Buttons>
            <Button
              disabled={!isSomethingSelected}
              loading={submitting}
              primary
              type="submit"
              block
            >
              {__(`Uložiť a použiť (${selectedFiles.length})`)}
            </Button>
          </Buttons>
        </>
      )}
    </form>
  );
};

FileForm.propTypes = {
  cancelButtonClick: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  appId: PropTypes.string.isRequired,
  appSpace: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  deleteFiles: PropTypes.func.isRequired,
  searchFiles: PropTypes.func.isRequired,
  selectedFilesIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedFiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  confirm: PropTypes.func.isRequired,
  onSelectFilesFnAvailable: PropTypes.bool.isRequired,
};

FileForm.contextTypes = {
  onSelectFiles: PropTypes.func,
};

FileForm.defaultProps = {
  cancelButtonClick: () => {},
};

const ReduxFileForm = reduxForm({
  form: 'fileform',
  enableReinitialize: true,
})(FileForm);

const mapDispatchToProps = {
  searchFiles: actions.searchFiles,
  deleteFiles: actions.deleteFiles,
  emptyFileSelection: actions.emptyFileSelection,
};

const mapStateToProps = (state) => {
  return {
    appSpace: appSpaceSelector(state),
    accessToken: accessTokenSelector(state),
    appId: appIdSelector(state),
    selectedFiles: allFilesInSelectionSelector(state),
    selectedFilesIds: selectedFilesIdsSelector(state),
    onSelectFilesFnAvailable: onSelectFilesFnAvailableSelector(state),
  };
};

export default compose(
  withPopups,
  connect(mapStateToProps, mapDispatchToProps),
)(ReduxFileForm);
