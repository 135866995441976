import { __, paramsToObject } from '../../utilities/common';
import {
  accessTokenSelector,
  appIdSelector,
  appSpaceSelector,
} from '../App/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  setAccessToken,
  setAppId,
  setAppSpace,
  setOnSelectFilesFnAvailable,
} from '../App/actions';
import Api from '../../Api';
import Button from '@sportnet/ui/Button';
import CoreApi from '../../CoreApi';
import FormGroup from '@sportnet/ui/FormGroup';
import Input from '@sportnet/ui/Input';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import withPopups from '../../components/WithPopups';

const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`;

const Form = styled.form`
  text-align: center;
`;

class CommProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.processSingleAlienMessage = this.processSingleAlienMessage.bind(this);
    this.instanceId = paramsToObject(window.location.search).instance_id || '';
    this.handleClickButton = this.handleClickButton.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      appId: '',
      appSpace: '',
      accessToken: '',
    };
  }

  getChildContext() {
    const onSelectFilesExpiresCheck = (files) => {
      return files.some((f) => !!f.expiresAt);
    };
    if (this.instanceId) {
      return {
        onSelectFiles: async (files) => {
          let canContinue = true;
          if (onSelectFilesExpiresCheck(files)) {
            canContinue = await this.props.confirm(
              'Niektoré zvolené súbory majú dočasnú platnosť. Po jej uplynutí budú automaticky zmazané. Naozaj ich chcete použiť v obsahu?',
            );
          }
          canContinue &&
            window.parent.postMessage(
              [this.formatMessage('FILES_SELECTED', files)],
              '*',
            );
        },
        onClose: () => {
          window.parent.postMessage([this.formatMessage('CLOSE')], '*');
        },
      };
    }
    return {
      // onSelectFiles: async (files) => {
      //   let canContinue = true;
      //   if (onSelectFilesExpiresCheck(files)) {
      //     canContinue = await this.props.confirm(
      //       'Niektoré zvolené súbory majú dočasnú platnosť. Po jej uplynutí budú automaticky zmazané. Naozaj ich chcete použiť v obsahu?',
      //     );
      //   }
      //   canContinue &&
      //     alert('Files selected \n' + JSON.stringify(files, null, 2));
      // },
      onClose: () => {
        this.processSingleAlienMessage({ type: 'SET_APP_ID', payload: '' });
        this.processSingleAlienMessage({
          type: 'SET_APP_SPACE',
          payload: '',
        });
        this.processSingleAlienMessage({
          type: 'SET_ACCESS_TOKEN',
          payload: '',
        });
        this.setState({
          appId: '',
          appSpace: '',
          accessToken: '',
        });
        window.location.href = `/`;
      },
    };
  }

  UNSAFE_componentWillMount() {
    if (!this.instanceId) {
      this.setState({
        appId: paramsToObject(window.location.search).appId || '',
        appSpace: paramsToObject(window.location.search).appSpace || '',
        accessToken: paramsToObject(window.location.search).accessToken || '',
      });
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    // for testing and development purposes
    const token = '';
    Api.setToken(token);
    CoreApi.setToken(token);
    dispatch(setAccessToken(token));
    dispatch(setAppId(''));
    // dispatch(setAppSpace(''));

    window.addEventListener(
      'message',
      (e) => {
        if (Array.isArray(e.data)) {
          this.processAlienMessages(e.data);
        }
      },
      false,
    );

    if (!this.instanceId) {
      this.processSingleAlienMessage({
        type: 'SET_APP_ID',
        payload: paramsToObject(window.location.search).appId || '',
      });
      this.processSingleAlienMessage({
        type: 'SET_APP_SPACE',
        payload: paramsToObject(window.location.search).appSpace || '',
      });
      this.processSingleAlienMessage({
        type: 'SET_ACCESS_TOKEN',
        payload: paramsToObject(window.location.search).accessToken || '',
      });
    } else {
      window.parent.postMessage(
        [this.formatMessage('MEDIAMANAGER_READY')],
        '*',
      );
    }
  }

  handleChange(e) {
    const {
      target: { name, value },
    } = e;
    this.setState({
      [name]: value,
    });
  }

  handleClickButton() {
    const { appId, appSpace, accessToken } = this.state;
    window.location.href = `/?appId=${appId}&appSpace=${appSpace}&accessToken=${accessToken}`;
  }

  processAlienMessages(messages = []) {
    messages.forEach(this.processSingleAlienMessage);
  }

  formatMessage(type, payload) {
    return {
      instanceId: this.instanceId,
      type,
      payload,
    };
  }

  processSingleAlienMessage({ type, payload }) {
    const { dispatch } = this.props;

    try {
      switch (type) {
        case 'SET_ACCESS_TOKEN':
          if (typeof payload === 'string') {
            Api.setToken(payload);
            CoreApi.setToken(payload);
            dispatch(setAccessToken(payload));
          } else {
            throw Error(
              `Invalid ${type} action payload "${payload}", must be string.`,
            );
          }
          break;
        case 'SET_APP_ID':
          if (typeof payload === 'string') {
            dispatch(setAppId(payload));
          } else {
            throw Error(
              `Invalid ${type} action payload "${payload}", must be string.`,
            );
          }
          break;
        case 'SET_APP_SPACE':
          if (typeof payload === 'string') {
            dispatch(setAppSpace(payload));
          }
          break;
        case 'ON_SELECT_FILES_FN_SET':
          console.log('setOnSelectFilesFnAvailable', payload);
          dispatch(setOnSelectFilesFnAvailable(payload));
          break;
        default:
      }
    } catch (e) {
      window.parent.postMessage(
        [
          {
            type: 'ERROR_GENERIC',
            payload: e.message,
          },
        ],
        '*',
      );
    }
  }

  render() {
    const { accessToken, appId, children } = this.props;
    const {
      accessToken: accessTokenState,
      appId: appIdState,
      appSpace: appSpaceState,
    } = this.state;

    if (!this.instanceId) {
      if (!appId || !accessToken) {
        return (
          <Center>
            <Form>
              <FormGroup>
                <Input
                  name="appId"
                  placeholder="APP ID"
                  onChange={this.handleChange}
                  value={appIdState}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  name="appSpace"
                  placeholder="APP SPACE"
                  value={appSpaceState}
                  onChange={this.handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  name="accessToken"
                  placeholder="Token"
                  onChange={this.handleChange}
                  value={accessTokenState}
                />
              </FormGroup>
              <Button onClick={this.handleClickButton} primary type="submit">
                {__('Prehľadávať')}
              </Button>
            </Form>
          </Center>
        );
      }
    } else if (!accessToken || !appId) {
      return null;
    }
    return children;
  }
}

CommProvider.propTypes = {
  accessToken: PropTypes.string,
  appId: PropTypes.string,
  appSpace: PropTypes.string,
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
};

CommProvider.defaultProps = {
  accessToken: null,
  appId: null,
  appSpace: null,
};

CommProvider.childContextTypes = {
  onSelectFilesFnAvailable: PropTypes.bool,
  onSelectFiles: PropTypes.func,
  onClose: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    accessToken: accessTokenSelector(state),
    appId: appIdSelector(state),
    appSpace: appSpaceSelector(state),
  };
};

export default compose(withPopups, connect(mapStateToProps))(CommProvider);
