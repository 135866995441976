import * as React from 'react';
import { rem } from 'polished';
import Loader from '@sportnet/ui/Loader';
import styled from 'styled-components';

const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => rem(theme.grid.gutterWidth * 2)} 0;
`;

export default () => (
  <Wrapper>
    <Loader size="xl" />
  </Wrapper>
);
