import { LIST_NAME } from './constants';
import { createSelector } from 'reselect';
import { getListResults } from '@sportnet/redux-list';

const fileEnitityDomainSelector = (state) => state.entities.files;
const filesDomainSelector = (state) => state.files;
export const searchFilterSelector = (state) => state.searchFilter;

export const filesDraggingSelector = (state) => state.filesDragging;

export const filesSelector = createSelector(
  fileEnitityDomainSelector,
  getListResults(LIST_NAME),
  (fileEntities, ids) => (ids || []).map((id) => fileEntities.byId[id]),
);

export const selectedFilesSelector = createSelector(
  filesSelector,
  filesDomainSelector,
  (files, filesDomain) =>
    Object.entries(filesDomain.selected)
      .reduce((acc, [id, selected]) => {
        if (selected) {
          acc.push(id);
        }
        return acc;
      }, [])
      .map((idx) => files.find((file) => file._id === idx))
      .filter((a) => a),
);

export const selectedFilesByIdSelector = createSelector(
  filesDomainSelector,
  (filesDomain) => filesDomain.selected,
);

export const selectedFilesIdsSelector = createSelector(
  filesDomainSelector,
  (filesDomain) => Object.keys(filesDomain.selected),
);

export const allFilesSelector = createSelector(
  fileEnitityDomainSelector,
  (files) => files.byId,
);

export const allFilesInSelectionSelector = createSelector(
  allFilesSelector,
  filesDomainSelector,
  (files, filesDomain) => {
    return Object.keys(filesDomain.selected)
      .map((idx) => {
        const file = files[idx];
        return file;
      })
      .filter((a) => a);
  },
);
