import { __ } from '../../utilities/common';
import { allFilesInSelectionSelector } from '../Files/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { rem } from 'polished';
import FileForm from '../FileForm';
import PropTypes from 'prop-types';
import React from 'react';
import Sidebar from '@sportnet/ui/Sidebar';
import styled from 'styled-components';

const TitleWrapper = styled.div`
  color: ${({ theme }) => theme.color.primary};
  background-color: ${({ theme }) => theme.color.primaryBg};
  padding: ${rem(18)} ${rem(40)};
  font-size: ${rem(14)};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const wordFilesFormater = (count) => {
  if (count === 1) {
    return __(`Máte označený ${count} súbor`);
  }
  if (count >= 2 && count <= 4) {
    return __(`Máte označené ${count} súbory`);
  }
  return __(`Máte označených ${count} súborov`);
};

const SidebarDetail = ({
  selectedFiles,
  opened,
  onFileSelectionTitleClick,
  callBackhandleOpen,
}) => {
  return (
    <Sidebar
      visible={opened}
      onClose={callBackhandleOpen}
      header={__('Úprava súborov')}
    >
      {Object.keys(selectedFiles).length > 1 && (
        <TitleWrapper onClick={onFileSelectionTitleClick}>
          {wordFilesFormater(Object.keys(selectedFiles).length)}
        </TitleWrapper>
      )}
      <FileForm />
    </Sidebar>
  );
};

SidebarDetail.propTypes = {
  selectedFiles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  opened: PropTypes.bool,
  callBackhandleOpen: PropTypes.func.isRequired,
  onFileSelectionTitleClick: PropTypes.func.isRequired,
};

SidebarDetail.defaultProps = {
  opened: true,
};

const mapStateToProps = (state) => {
  return {
    selectedFiles: allFilesInSelectionSelector(state),
  };
};

export default compose(connect(mapStateToProps))(SidebarDetail);
