import { MEDIA_MANAGER_DEFAULT_IMAGES_URL } from '../../utilities/common';
import { isPicture } from '../../utilities/formatting';
import Image from '../../components/Image';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Tooltip from '../../components/Tooltip';

class FilePreview extends Component {
  render() {
    const {
      file: { filepath, name, mimetype },
      className,
      thumbnailsSize,
    } = this.props;
    if (isPicture(mimetype)) {
      const thsize =
        thumbnailsSize === 'L'
          ? [150, 100]
          : thumbnailsSize === 'M'
          ? [90, 60]
          : [40, 40];
      return (
        <Tooltip
          className={className}
          content={
            <img
              alt={name}
              src={`${MEDIA_MANAGER_DEFAULT_IMAGES_URL}/400x400xresize/${filepath}`}
              style={{ display: 'block' }}
            />
          }
        >
          <Image
            width={thsize[0]}
            height={thsize[1]}
            src={`${MEDIA_MANAGER_DEFAULT_IMAGES_URL}/${thsize[0]}x${thsize[1]}xresize/${filepath}`}
          />
        </Tooltip>
      );
    }
    return null;
  }
}

FilePreview.propTypes = {
  className: PropTypes.string,
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    filepath: PropTypes.string.isRequired,
    mimetype: PropTypes.string.isRequired,
  }).isRequired,
};

FilePreview.defaultProps = {
  className: null,
};

export default FilePreview;
