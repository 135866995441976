import {
  FETCH_FOLDER_TREE_FAILURE,
  FETCH_FOLDER_TREE_REQUEST,
  FETCH_FOLDER_TREE_SUCCESS,
  SET_ACTIVE_FOLDER,
  SET_FOLDER_TREE,
} from './constants';

const folderTreeInitialState = {
  folders: [],
  isFetching: true,
  error: false,
  activeId: null,
};

const folderTreeReducer = (state = folderTreeInitialState, action) => {
  switch (action.type) {
    case FETCH_FOLDER_TREE_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_FOLDER_TREE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_FOLDER_TREE_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case SET_FOLDER_TREE: {
      return {
        ...state,
        folders: action.payload.folders,
      };
    }
    case SET_ACTIVE_FOLDER: {
      return {
        ...state,
        activeId: action.payload.activeId,
      };
    }
    default:
      return state;
  }
};
export default folderTreeReducer;
