export const __ = (toTranslate) => {
  return toTranslate;
};

export const paramsToObject = (params) => {
  const query = params.substr(1);
  const result = {};
  query.split('&').forEach((part) => {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};

export const MEDIA_MANAGER_DEFAULT_IMAGES_URL = process.env
  .REACT_APP_IMAGES_BASE_URL
  ? process.env.REACT_APP_IMAGES_BASE_URL
  : 'https://mediamanager.ws/images';
