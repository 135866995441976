import { createRoot } from 'react-dom/client';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { ThemeProvider, withTheme } from 'styled-components';

const Wrapper = styled.div`
  position: relative;
`;

const TRIANGLE_SIZE_PX = 7;

const TooltipWindow = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  background: ${({ theme }) => theme.backgroundColor};
  position: fixed;
  z-index: 30;
  top: ${({ top }) => top}px;
  transform: translate(0, -50%);
  left: ${({ left }) => left + TRIANGLE_SIZE_PX * 2}px;
  box-shadow: ${({ theme }) => theme.shadow};
  padding: ${rem(5)};
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-${TRIANGLE_SIZE_PX * 2}px, -50%);
    width: 0;
    height: 0;
    border-left: ${rem(TRIANGLE_SIZE_PX)} solid transparent;
    border-right: ${rem(TRIANGLE_SIZE_PX)} solid
      ${({ theme }) => theme.backgroundColor};
    border-bottom: ${rem(TRIANGLE_SIZE_PX)} solid transparent;
    border-top: ${rem(TRIANGLE_SIZE_PX)} solid transparent;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: ${rem(TRIANGLE_SIZE_PX)};
    bottom: 0;
    transform: translateY(100%);
  }
`;

class Tooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovering: false,
    };
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.childrenEl = null;
    this.bodyEl = null;
    this.root = null;
  }

  componentDidMount() {
    this.bodyEl = document.createElement('div');
    this.root = createRoot(this.bodyEl);
    // document.body.appendChild(this.bodyEl);
  }

  componentDidUpdate() {
    const { content, theme } = this.props;
    const { hovering } = this.state;

    if (content && hovering) {
      const bcr = this.childrenEl.getBoundingClientRect();
      this.root.render(
        <ThemeProvider theme={theme}>
          <TooltipWindow
            top={bcr.top + bcr.height / 2}
            left={bcr.left + bcr.width}
          >
            {content}
          </TooltipWindow>
        </ThemeProvider>,
      );
    } else {
      this.root.render(null);
    }
  }

  componentWillUnmount() {
    if (this.bodyEl && this.bodyEl.parentNode) {
      this.bodyEl.parentNode.removeChild(this.bodyEl);
    }
  }

  handleMouseEnter() {
    this.bodyEl.style.display = 'block';
    document.body.appendChild(this.bodyEl);
    this.setState({ hovering: true });
  }

  handleMouseLeave() {
    this.bodyEl.style.display = 'none';
    document.body.removeChild(this.bodyEl);
    this.setState({ hovering: false });
  }

  render() {
    const { className, children } = this.props;
    return (
      <Wrapper
        className={className}
        onMouseLeave={this.handleMouseLeave}
        onMouseOver={this.handleMouseEnter}
      >
        <div
          ref={(el) => {
            this.childrenEl = el;
          }}
        >
          {children}
        </div>
      </Wrapper>
    );
  }
}

Tooltip.propTypes = {
  theme: PropTypes.shape({}).isRequired,
  content: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
};

Tooltip.defaultProps = {
  content: null,
  children: null,
  className: '',
};

export default withTheme(Tooltip);
