import { rem } from 'polished';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.label`
  display: inline-block;
  align-items: center;
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.form.labelColor};
  font-weight: bold;
`;

const Label = (props, context) => {
  const { children, className, htmlFor } = props;
  const { id } = context;
  return (
    <Wrapper htmlFor={htmlFor || id} className={className}>
      {children}
    </Wrapper>
  );
};

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  htmlFor: PropTypes.string,
};
Label.defaultProps = {
  className: '',
  children: null,
  htmlFor: null,
};

Label.contextTypes = {
  id: PropTypes.string,
};

export default Label;
