import { LIST_LIMIT } from '../Files/constants';
import { allFilesInSelectionSelector } from '../Files/selectors';
import { change, getFormValues, reset } from 'redux-form';
import { connect } from 'react-redux';
import { editFiles } from './actions';
import { emptyFileSelection, searchFiles } from '../Files/actions';
import { getProp } from '@sportnet/utilities';
import { isEqual } from '@sportnet/tagmanager-connector';
import Form from './form';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Segment from '@sportnet/ui/Segment';

const FORM_NAME = 'fileform';

class FileForm extends PureComponent {
  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.smartTagOnAdd = this.smartTagOnAdd.bind(this);
    this.smartTagOnDelete = this.smartTagOnDelete.bind(this);
    this.cancelButtonClick = this.cancelButtonClick.bind(this);
  }

  componentDidMount() {
    const { fileDetails } = this.props;
    if (fileDetails.length > 0) {
      this.initForm(fileDetails);
    }
  }

  componentDidUpdate(prevProps) {
    const { fileDetails } = prevProps;
    const { fileDetails: nextFileDetails, dispatch } = this.props;
    // when sidebar closes, reset form
    if (fileDetails.length > 0 && nextFileDetails.length === 0) {
      dispatch(reset(FORM_NAME));
    }

    if (fileDetails !== nextFileDetails) {
      this.initForm(nextFileDetails);
    }
  }

  initForm = (nextFileDetails) => {
    const { dispatch } = this.props;

    let expiresAtAreSame = true;
    let namesAreSame = true;
    let authorsAreSame = true;
    let descriptionsAreSame = true;
    let sourcesAreSame = true;
    let smarttagsAreSame = true;
    let expiresAt;
    let name;
    let author;
    let description;
    let source;
    let smarttags;
    for (let i = 0; i < nextFileDetails.length; i++) {
      expiresAt = getProp(nextFileDetails, [i, 'expiresAt']);
      name = getProp(nextFileDetails, [i, 'name']);
      author = getProp(nextFileDetails, [i, 'author']);
      description = getProp(nextFileDetails, [i, 'description']);
      source = getProp(nextFileDetails, [i, 'source']);
      smarttags = getProp(nextFileDetails, [i, 'smarttags']);
      if (nextFileDetails.length > 1) {
        if (getProp(nextFileDetails, [i - 1])) {
          if (expiresAt !== getProp(nextFileDetails, [i - 1, 'expiresAt'])) {
            expiresAtAreSame = false;
          }
          if (name !== getProp(nextFileDetails, [i - 1, 'name'])) {
            namesAreSame = false;
          }
          if (author !== getProp(nextFileDetails, [i - 1, 'author'])) {
            authorsAreSame = false;
          }
          if (
            description !== getProp(nextFileDetails, [i - 1, 'description'])
          ) {
            descriptionsAreSame = false;
          }
          if (source !== getProp(nextFileDetails, [i - 1, 'source'])) {
            sourcesAreSame = false;
          }
          if (
            !isEqual(smarttags, getProp(nextFileDetails, [i - 1, 'smarttags']))
          ) {
            smarttagsAreSame = false;
          }
        }
      }
    }
    if (expiresAtAreSame) {
      dispatch(change(FORM_NAME, 'expiresAt', expiresAt));
      dispatch(change(FORM_NAME, 'editExpiresAt', true));
    } else {
      dispatch(change(FORM_NAME, 'expiresAt', ''));
      dispatch(change(FORM_NAME, 'editExpiresAt', false));
    }
    if (namesAreSame) {
      dispatch(change(FORM_NAME, 'name', name));
      dispatch(change(FORM_NAME, 'editName', true));
    } else {
      dispatch(change(FORM_NAME, 'name', ''));
      dispatch(change(FORM_NAME, 'editName', false));
    }
    if (authorsAreSame) {
      dispatch(change(FORM_NAME, 'author', author));
      dispatch(change(FORM_NAME, 'editAuthor', true));
    } else {
      dispatch(change(FORM_NAME, 'author', ''));
      dispatch(change(FORM_NAME, 'editAuthor', false));
    }
    if (descriptionsAreSame) {
      dispatch(change(FORM_NAME, 'description', description));
      dispatch(change(FORM_NAME, 'editDescription', true));
    } else {
      dispatch(change(FORM_NAME, 'description', ''));
      dispatch(change(FORM_NAME, 'editDescription', false));
    }
    if (sourcesAreSame) {
      dispatch(change(FORM_NAME, 'source', source));
      dispatch(change(FORM_NAME, 'editSource', true));
    } else {
      dispatch(change(FORM_NAME, 'source', ''));
      dispatch(change(FORM_NAME, 'editSource', false));
    }
    if (smarttagsAreSame) {
      dispatch(change(FORM_NAME, 'smarttags', smarttags));
      dispatch(change(FORM_NAME, 'editSmartTags', true));
    } else {
      dispatch(change(FORM_NAME, 'smarttags', []));
      dispatch(change(FORM_NAME, 'editSmartTags', false));
    }
  };

  cancelButtonClick() {
    const { dispatch } = this.props;
    dispatch(emptyFileSelection());
    dispatch(reset(FORM_NAME));
  }

  smartTagOnAdd(smartTagId, tagId) {
    const { dispatch } = this.props;
    const tagIds = Object.assign(
      {},
      getProp(this.props, ['values', 'smarttags'], {}),
    );
    tagIds[smartTagId] = getProp(tagIds, [`${smartTagId}`], []);
    tagIds[smartTagId].push(tagId);
    dispatch(change(FORM_NAME, 'smarttags', tagIds));
  }

  smartTagOnDelete(smartTagId, tagId) {
    const { dispatch } = this.props;
    const tagIds = Object.assign(
      {},
      getProp(this.props, ['values', 'smarttags'], {}),
    );
    let smartTag = tagIds[smartTagId];
    const idx = smartTag.indexOf(smartTag.find((item) => item.id === tagId));
    smartTag = [...smartTag.slice(0, idx), ...smartTag.slice(idx + 1)];
    tagIds[smartTagId] = smartTag;
    if (!tagIds[smartTagId].length) {
      delete tagIds[smartTagId];
    }
    dispatch(change(FORM_NAME, 'smarttags', tagIds));
  }

  async handleFormSubmit(data) {
    const { dispatch, fileDetails } = this.props;
    const filesToUpdate = fileDetails.map((fileDetail) => {
      const file = {
        _id: fileDetail._id,
      };
      if (data.editExpiresAt === true) {
        const expiresAt = getProp(data, ['expiresAt'], null);
        file.expiresAt = expiresAt ? new Date(expiresAt).toISOString() : null;
      }
      if (data.editName === true) {
        file.name = getProp(data, ['name'], '');
      }
      if (data.editAuthor === true) {
        file.author = getProp(data, ['author'], '');
      }
      if (data.editDescription === true) {
        file.description = getProp(data, ['description'], '');
      }
      if (data.editSource === true) {
        file.source = getProp(data, ['source'], '');
      }
      if (data.editSmartTags === true) {
        file.smarttags = getProp(data, ['smarttags'], []).reduce(
          (acc, next) => {
            if (getProp(next, ['values'], []).length > 0) {
              return [...acc, next];
            }
            return acc;
          },
          [],
        );
      }
      return file;
    });
    const editedFiles = await dispatch(editFiles(filesToUpdate));
    dispatch(searchFiles(LIST_LIMIT, false));
    dispatch(emptyFileSelection());

    if (!data.saveOnly) {
      if (this.context.onSelectFiles) {
        this.context.onSelectFiles(editedFiles);
      }
    }
  }

  render() {
    const { values } = this.props;

    return (
      <Segment>
        <Form
          onSubmit={this.handleFormSubmit}
          smartTagOnAdd={this.smartTagOnAdd}
          smartTagOnDelete={this.smartTagOnDelete}
          cancelButtonClick={this.cancelButtonClick}
          valuesSelected={values}
        />
      </Segment>
    );
  }
}

FileForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fileDetails: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      filepath: PropTypes.string,
      media_url: PropTypes.string,
      mimetype: PropTypes.string,
      public_url: PropTypes.string,
      size: PropTypes.number,
      id: PropTypes.string,
    }),
  ).isRequired,
  values: PropTypes.shape({
    name: PropTypes.string,
  }),
};

FileForm.defaultProps = {
  values: null,
};

FileForm.contextTypes = {
  onSelectFiles: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    fileDetails: allFilesInSelectionSelector(state),
    values: getFormValues(FORM_NAME)(state),
  };
};

export default connect(mapStateToProps)(FileForm);
