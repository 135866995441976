import { processDroppedFile } from '../utilities';
import { rem, transparentize } from 'polished';
import Icon from '@sportnet/ui/Icon';
import Media from 'react-media';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';

const styles = {};

const RowButtons = styled.div`
  margin-left: auto;
  display: none;
  @media (pointer: coarse) {
    display: flex;
  }
`;

const NodeContent = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: ${rem(12)};
  font-weight: ${({ isFirstLevel }) => (isFirstLevel ? 'bold' : 'normal')};
  transition: background-color 0.4s;
  ${({ theme, isSearchMatch }) =>
    isSearchMatch &&
    css`
      background-color: ${transparentize(0.8, theme.color.primary)};
    `};
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.primary : theme.textColor};
  &:hover ${RowButtons} {
    display: flex;
  }
  ${({ theme, hovering }) =>
    hovering &&
    css`
      border: 2px dotted ${theme.color.primary};
    `};

  padding: 0 ${rem(10)};
`;

const RowWrapper = styled.div`
  display: flex;
  flex: 1 0 0;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  white-space: nowrap;
  position: relative;
  flex: 1 0 0;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  margin-left: ${rem(10)};
`;

const ExpandButton = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  padding: 0;
  z-index: 2;
  position: absolute;
  top: 50%;
  width: auto;
  height: 30px;
  transform: translate3d(-50%, -50%, 0);
  cursor: pointer;
  outline: none;
  margin-left: 10px;
`;

const ActionButton = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  padding: 0;
  z-index: 2;
  cursor: pointer;
  margin-left: ${rem(12)};
`;

const NodeTitle = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledExpandIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  transition: transform 0.1s;
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(0)' : 'rotate(-90deg)'};
`;

class FileThemeNodeContentRenderer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovering: 0,
      isTouchDevice: false,
    };
  }

  render() {
    const {
      scaffoldBlockPxWidth,
      toggleChildrenVisibility,
      connectDragPreview,
      connectDragSource,
      isDragging,
      canDrop,
      canDrag,
      node,
      title,
      draggedNode,
      path,
      treeIndex,
      isSearchMatch,
      isSearchFocus,
      icons,
      buttons,
      className,
      style,
      didDrop,
      lowerSiblingCounts,
      listIndex,
      swapFrom,
      swapLength,
      swapDepth,
      treeId, // Not needed, but preserved for other renderers
      isOver, // Not needed, but preserved for other renderers
      parentNode, // Needed for dndManager
      onClickNodeTitle,
      onDropFile,
      activeNodeId,
      ...otherProps
    } = this.props;
    const nodeTitle = title || node.title;
    const { hovering, isTouchDevice } = this.state;
    // Construct the scaffold representing the structure of the tree
    const scaffold = [];
    lowerSiblingCounts.forEach((lowerSiblingCount, i) => {
      scaffold.push(
        <div
          key={`pre_${1 + i}`}
          style={{ width: scaffoldBlockPxWidth }}
          className={styles.lineBlock}
        />,
      );

      if (treeIndex !== listIndex && i === swapDepth) {
        // This row has been shifted, and is at the depth of
        // the line pointing to the new destination
        let highlightLineClass = '';

        if (listIndex === swapFrom + swapLength - 1) {
          // This block is on the bottom (target) line
          // This block points at the target block (where the row will go when released)
          highlightLineClass = styles.highlightBottomLeftCorner;
        } else if (treeIndex === swapFrom) {
          // This block is on the top (source) line
          highlightLineClass = styles.highlightTopLeftCorner;
        } else {
          // This block is between the bottom and top
          highlightLineClass = styles.highlightLineVertical;
        }

        scaffold.push(
          <div
            key={`highlight_${1 + i}`}
            style={{
              width: scaffoldBlockPxWidth,
              left: scaffoldBlockPxWidth * i,
            }}
            className={`${styles.absoluteLineBlock} ${highlightLineClass}`}
          />,
        );
      }
    });

    const nodeLabel =
      typeof nodeTitle === 'function'
        ? nodeTitle({
            node,
            path,
            treeIndex,
          })
        : nodeTitle;

    const nodeContent = (
      <>
        <Media
          query="(pointer: coarse)"
          onChange={(matches) => this.setState({ isTouchDevice: matches })}
        />
        <NodeContent
          {...otherProps}
          isSearchMatch={isSearchMatch}
          isFirstLevel={lowerSiblingCounts.length === 1}
          isActive={
            typeof activeNodeId !== 'undefined' && activeNodeId === node._id
          }
          onDrop={(ev) => {
            this.setState({ hovering: 0 });
            const id = processDroppedFile(ev);
            if (id === null) {
              console.error('Failed to parse json');
            } else {
              onDropFile(node._id, id);
            }
          }}
          hovering={hovering > 0}
          onDragEnter={(ev) => {
            ev.preventDefault();
            this.setState({ hovering: hovering + 1 });
          }}
          onDragLeave={(ev) => {
            ev.preventDefault();
            this.setState({ hovering: hovering - 1 });
          }}
          onDragOver={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
          }}
        >
          {toggleChildrenVisibility &&
            node.children &&
            node.children.length > 0 && (
              <ExpandButton
                type="button"
                aria-label={node.expanded ? 'Collapse' : 'Expand'}
                className={
                  node.expanded ? styles.collapseButton : styles.expandButton
                }
                style={{
                  left:
                    (lowerSiblingCounts.length - 0.7) * scaffoldBlockPxWidth,
                }}
                onClick={() =>
                  toggleChildrenVisibility({
                    node,
                    path,
                    treeIndex,
                  })
                }
                data-testid="MM_FolderTree_Icon_Expand"
              >
                <StyledExpandIcon
                  isExpanded={node.expanded}
                  name="chevron"
                  size={isTouchDevice ? 30 : 15}
                />
              </ExpandButton>
            )}

          <RowWrapper>
            {/* Set the row preview to be used during drag and drop */}
            {connectDragPreview(
              <div
                style={{
                  display: 'flex',
                  flex: '1 0 0',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                }}
              >
                {scaffold}
                <Row
                  onClick={() => {
                    onClickNodeTitle(node);
                  }}
                >
                  <NodeTitle title={nodeLabel}>{nodeLabel}</NodeTitle>
                </Row>
                {!!buttons && (
                  <RowButtons>
                    {buttons.map((button) => (
                      <ActionButton
                        key={button.icon}
                        type="button"
                        onClick={() => {
                          button.onClick(node);
                        }}
                        data-testid={`MM_FolderTree_Icon_${button.icon}`}
                      >
                        <Icon
                          name={button.icon}
                          size={isTouchDevice ? 'm' : 's'}
                        />
                      </ActionButton>
                    ))}
                  </RowButtons>
                )}
              </div>,
            )}
          </RowWrapper>
        </NodeContent>
      </>
    );

    return canDrag
      ? connectDragSource(nodeContent, { dropEffect: 'copy' })
      : nodeContent;
  }
}

FileThemeNodeContentRenderer.defaultProps = {
  buttons: [],
  canDrag: false,
  canDrop: false,
  className: '',
  draggedNode: null,
  icons: [],
  isSearchFocus: false,
  isSearchMatch: false,
  parentNode: null,
  style: {},
  swapDepth: null,
  swapFrom: null,
  swapLength: null,
  title: null,
  toggleChildrenVisibility: null,
  activeNodeId: null,
};

FileThemeNodeContentRenderer.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      icon: PropTypes.string.isRequired,
    }),
  ),
  canDrag: PropTypes.bool,
  className: PropTypes.string,
  icons: PropTypes.arrayOf(PropTypes.node),
  isSearchFocus: PropTypes.bool,
  isSearchMatch: PropTypes.bool,
  listIndex: PropTypes.number.isRequired,
  lowerSiblingCounts: PropTypes.arrayOf(PropTypes.number).isRequired,
  node: PropTypes.shape({
    expanded: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.shape({})),
    _id: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  path: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  scaffoldBlockPxWidth: PropTypes.number.isRequired,
  style: PropTypes.shape({}),
  swapDepth: PropTypes.number,
  swapFrom: PropTypes.number,
  swapLength: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  toggleChildrenVisibility: PropTypes.func,
  treeIndex: PropTypes.number.isRequired,
  treeId: PropTypes.string.isRequired,
  onClickNodeTitle: PropTypes.func.isRequired,
  onDropFile: PropTypes.func.isRequired,
  activeNodeId: PropTypes.string,

  // Drag and drop API functions
  // Drag source
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  didDrop: PropTypes.bool.isRequired,
  draggedNode: PropTypes.shape({}),
  isDragging: PropTypes.bool.isRequired,
  parentNode: PropTypes.shape({}), // Needed for dndManager
  // Drop target
  canDrop: PropTypes.bool,
  isOver: PropTypes.bool.isRequired,
};

export default FileThemeNodeContentRenderer;
