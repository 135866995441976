import 'normalize.css';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import { createRoot } from 'react-dom/client';
import Api from './Api';
import App from './containers/App';
import CommProvider from './containers/CommProvider';
import CoreApi from './CoreApi';
import Popup from '@sportnet/ui/Popup';
import React from 'react';
import baseTheme from './theme/base';
import reducer from './reducer';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import thunkMiddleware from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reduxMiddleware = [thunkMiddleware];

Api.setBaseUrl(process.env.REACT_APP_API_BASE_URL);
CoreApi.setBaseUrl(process.env.REACT_APP_SPORTNET_API_BASE_URL);

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,500&subset=latin-ext');
  html, body {
    font-size: 16px;
    font-family: Roboto, 'sans-serif';
    height: 100%;
    background-color: #fff;
  }
  #root {
    height: 100%;
  }
  * {
    box-sizing: border-box;
  }
  [type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: button;
  }
  .react-datepicker__time-container {
    width: 75px !important;
  }
`;

const Wrapper = styled.div`
  font-family: ${(props) => props.theme.fontFamily};
  height: 100%;
  color: ${(props) => props.theme.textColor};
`;

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...reduxMiddleware)),
);

const element = document.getElementById('root');
const root = createRoot(element);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={baseTheme}>
      <GlobalStyle />
      <Wrapper>
        <Popup.PopupProvider>
          <CommProvider>
            <App />
          </CommProvider>
        </Popup.PopupProvider>
      </Wrapper>
    </ThemeProvider>
  </Provider>,
);
