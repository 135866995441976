import { CommitError, commit, getListParameters } from '@sportnet/redux-list';
import {
  DELETE_FILES_FAILURE,
  DELETE_FILES_REQUEST,
  DELETE_FILES_SUCCESS,
  DRAG_FILE_ENDED,
  DRAG_FILE_STARTED,
  FILE_SELECTION_ADD,
  FILE_SELECTION_CLEAR,
  FILE_SELECTION_REMOVE,
  LIST_LIMIT,
  LIST_NAME,
  MOVE_FILES_FAILURE,
  MOVE_FILES_REQUEST,
  MOVE_FILES_SUCCESS,
  SET_SEARCH_FILTER,
  UPDATE_FILE_ENITIES,
} from './constants';
import { appIdSelector, appSpaceSelector } from '../App/selectors';
import { selectedFilesIdsSelector } from './selectors';
import Api from '../../Api';
import Cookies from 'js-cookie';

function _flattenSmarttags(stags, op = '') {
  return stags.reduce((acc, stag) => {
    (stag.values || []).forEach((v) => {
      const formattedStag = `${stag.key}:${op ? `${op}:` : ''}${v.key}`;
      acc.push(encodeURIComponent(formattedStag));
    });
    return acc;
  }, []);
}

const smarttagsToUrl = (include = [], exclude = []) => {
  return _flattenSmarttags(include)
    .concat(_flattenSmarttags(exclude, 'not'))
    .join(',');
};

export const updateFileEntities = (files) => ({
  type: UPDATE_FILE_ENITIES,
  payload: {
    files,
  },
});

export const addFilesToSelection = (fileIds) => ({
  type: FILE_SELECTION_ADD,
  payload: {
    fileIds,
  },
});

export const removeFilesFromSelection = (fileIds) => ({
  type: FILE_SELECTION_REMOVE,
  payload: {
    fileIds,
  },
});

export const emptyFileSelection = () => ({
  type: FILE_SELECTION_CLEAR,
});

export const deleteFilesRequest = () => ({
  type: DELETE_FILES_REQUEST,
});

export const deleteFilesFailure = (error) => ({
  type: DELETE_FILES_FAILURE,
  payload: {
    error,
  },
});

/**
 * @param {string[]} fileIds
 */
export const deleteFilesSuccess = () => ({
  type: DELETE_FILES_SUCCESS,
});

/**
 * @param {string[]} fileIds
 */
export const deleteFiles = (fileIds) => {
  return async (dispatch, getState) => {
    dispatch(deleteFilesRequest());
    try {
      const appSpace = appSpaceSelector(getState());
      if (appSpace) {
        await Api.deleteFilesByIds(appIdSelector(getState()), appSpace, {
          body: fileIds,
        });
      } else {
        await Api.deleteUserFilesByIds({
          body: fileIds,
        });
      }
      dispatch(deleteFilesSuccess());
    } catch (error) {
      dispatch(deleteFilesFailure(error.message || 'Something went wrong'));
    }
  };
};

export const searchFiles = (
  { limit, emptySelection } = { limit: LIST_LIMIT, emptySelection: false },
) => {
  return async (dispatch, getState) => {
    const params = getListParameters(LIST_NAME)(getState());
    if (emptySelection) {
      dispatch(emptyFileSelection());
    }

    await dispatch(
      commit.action({
        listName: LIST_NAME,
        load: async () => {
          try {
            const appSpace = appSpaceSelector(getState());
            let response;
            const data = {
              parentId: params.directoryId || undefined,
              query: params.query !== null ? params.query : undefined,
              limit,
              offset: params.offset,
            };
            const displayFilesCategory =
              params.displayFilesCategory ||
              Cookies.get('displayFilesCategory') ||
              'all';
            if (displayFilesCategory === 'temporary') {
              data.showTemporaryFiles = true;
            } else if (displayFilesCategory === 'permanent') {
              data.showTemporaryFiles = false;
            }
            if (params.smarttag || [].length) {
              data.smarttag = smarttagsToUrl(params.smarttag);
            }
            if (appSpace) {
              response = await Api.getFiles(
                appIdSelector(getState()),
                appSpace,
                data,
              );
            } else {
              response = await Api.getUserFiles(data);
            }
            dispatch(updateFileEntities(response.files));
            return {
              total: response.total || 0,
              results: response.files.map((a) => a._id),
              nextOffset: null,
            };
          } catch (error) {
            throw new CommitError(error);
          }
        },
      }),
    );
  };
};

export const moveFilesRequest = () => ({
  type: MOVE_FILES_REQUEST,
});

export const moveFilesFailure = (error) => ({
  type: MOVE_FILES_FAILURE,
  payload: {
    error,
  },
});

export const moveFilesSuccess = () => ({
  type: MOVE_FILES_SUCCESS,
});

/**
 * @param {number} targetFolderId Id of folder where selected files should be moved
 * @param {number} draggedFileId Id of files that is being dragged - it may not be selected yet
 */
export const moveFiles = (targetFolderId, draggedFileId) => {
  return async (dispatch, getState) => {
    const selectedFileIds = [
      draggedFileId,
      ...selectedFilesIdsSelector(getState()),
    ];
    if (selectedFileIds.length === 0) {
      return;
    }
    dispatch(moveFilesRequest());
    try {
      const appSpace = appSpaceSelector(getState());
      const data = {
        body: {
          ids: selectedFileIds,
          destination_id: targetFolderId,
        },
      };
      if (appSpace) {
        await Api.moveFiles(appIdSelector(getState()), appSpace, data);
      } else {
        await Api.moveUserFiles(data);
      }
      dispatch(moveFilesSuccess());
    } catch (error) {
      dispatch(moveFilesFailure(error.message || 'Something went wrong'));
    }
  };
};

export const dragFileStarted = () => ({
  type: DRAG_FILE_STARTED,
});

export const dragFileEnded = () => ({
  type: DRAG_FILE_ENDED,
});

export const setSearchFilter = (filterParams) => ({
  type: SET_SEARCH_FILTER,
  payload: {
    filterParams,
  },
});
