import {
  DRAG_FILE_ENDED,
  DRAG_FILE_STARTED,
  FILE_SELECTION_ADD,
  FILE_SELECTION_CLEAR,
  FILE_SELECTION_REMOVE,
  SET_SEARCH_FILTER,
  UPDATE_FILE_ENITIES,
} from './constants';

const fileListInitialState = {
  selected: {},
};

const fileListReducer = (state = fileListInitialState, action) => {
  switch (action.type) {
    case FILE_SELECTION_ADD: {
      const newSelection = action.payload.fileIds.reduce((prev, id) => {
        return { ...prev, [id]: true };
      }, {});
      return {
        ...state,
        selected: {
          ...state.selected,
          ...newSelection,
        },
      };
    }
    case FILE_SELECTION_REMOVE: {
      const selected = { ...state.selected };
      action.payload.fileIds.forEach((id) => {
        delete selected[id];
      });
      return {
        ...state,
        selected,
      };
    }
    case FILE_SELECTION_CLEAR:
      return {
        ...state,
        selected: {},
      };
    default:
      return state;
  }
};

const entityInitialState = {
  byId: {},
};

const entityReducer = (state = entityInitialState, action) => {
  switch (action.type) {
    case UPDATE_FILE_ENITIES: {
      const newState = {
        ...state,
        byId: {
          ...state.byId,
        },
      };
      action.payload.files.forEach((file) => {
        newState.byId[file._id] = file;
      });
      return newState;
    }
    default:
      return state;
  }
};

const filesDraggingReducer = (state = false, action) => {
  switch (action.type) {
    case DRAG_FILE_STARTED:
      return true;
    case DRAG_FILE_ENDED:
      return false;
    default:
      return state;
  }
};

const searchFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_SEARCH_FILTER:
      return action.payload.filterParams;
    default:
      return state;
  }
};

export {
  entityReducer,
  fileListReducer,
  filesDraggingReducer,
  searchFilterReducer,
};
