import {
  ADD_FILES_FOR_UPLOAD,
  FETCH_APPINFO_FAILURE,
  FETCH_APPINFO_REQUEST,
  FETCH_APPINFO_SUCCESS,
  FETCH_APPSPACEUSER_FAILURE,
  FETCH_APPSPACEUSER_REQUEST,
  FETCH_APPSPACEUSER_SUCCESS,
  LOAD_USER_SUCCESS,
  UPDATE_SETTING,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
} from './constants';
import { __ } from '../../utilities/common';
import {
  appIdSelector,
  appSpaceSelector,
  uploadingFilesNotStartedSelector,
} from './selectors';
import Api from '../../Api';
import CoreApi from '../../CoreApi';

/**
 *
 * @param {File[]} files
 * @param {string} directoryId Null if, no directory is specified.
 */
export const addFilesForUpload = (files, directoryId = null) => ({
  type: ADD_FILES_FOR_UPLOAD,
  payload: {
    files,
    destinationDirectoryId: directoryId,
  },
});

/**
 *
 * @param {File} file
 * @param {string} directoryId Null if, no directory is specified.
 */
export const uploadFileRequest = (file) => ({
  type: UPLOAD_FILE_REQUEST,
  payload: {
    file,
  },
});

/**
 *
 * @param {File} file
 * @param {*} error
 */
export const uploadFileFailure = (file, error) => ({
  type: UPLOAD_FILE_FAILURE,
  payload: {
    file,
    error,
  },
});

/**
 *
 * @param {File} file
 */
export const uploadFileSuccess = (file, uploadedFileInfo) => ({
  type: UPLOAD_FILE_SUCCESS,
  payload: {
    file,
    uploadedFileInfo,
  },
});

export const uploadFiles = () => {
  return async (dispatch, getState) => {
    const files = uploadingFilesNotStartedSelector(getState());
    for await (const file of files) {
      dispatch(uploadFileRequest(file));
      try {
        let uploadedFileInfo;
        const appId = appIdSelector(getState());
        const appSpace = appSpaceSelector(getState());
        const data = {
          upload: file.file,
        };
        if (file.destinationDirectoryId) {
          data.destination = file.destinationDirectoryId;
        }
        if (appSpace) {
          uploadedFileInfo = await Api.uploadFile(appId, appSpace, {
            body: data,
          });
        } else {
          uploadedFileInfo = await Api.uploadUserFile({
            body: { ...data, appId },
          });
        }
        dispatch(uploadFileSuccess(file, uploadedFileInfo));
      } catch (e) {
        dispatch(uploadFileFailure(file));
      }
    }
    return true;
  };
};

export const setAccessToken = (accessToken) => ({
  type: UPDATE_SETTING,
  payload: {
    setting: 'accessToken',
    value: accessToken,
  },
});

export const setAppId = (appId) => ({
  type: UPDATE_SETTING,
  payload: {
    setting: 'appId',
    value: appId,
  },
});

export const setAppSpace = (appSpace) => ({
  type: UPDATE_SETTING,
  payload: {
    setting: 'appSpace',
    value: appSpace,
  },
});

/**
 * @param {number} available -1 - multiple selection available, 0 - no selection available, 1 - single selection available
 */
export const setOnSelectFilesFnAvailable = (available = -1) => ({
  type: UPDATE_SETTING,
  payload: {
    setting: 'onSelectFilesFnAvailable',
    value: available === 1 ? 1 : -1, // backward compatibility
  },
});

export const loadUserSuccess = (user) => ({
  type: LOAD_USER_SUCCESS,
  payload: {
    user,
  },
});

export const loadUser = () => {
  return async (dispatch) => {
    try {
      const response = await CoreApi.me();
      dispatch(loadUserSuccess(response));
    } catch (e) {
      console.error(e);
    }
  };
};

export const fetchRequestAppInfo = () => ({
  type: FETCH_APPINFO_REQUEST,
  payload: {},
});

export const fetchSuccessAppInfo = (appInfo) => ({
  type: FETCH_APPINFO_SUCCESS,
  payload: {
    appInfo,
  },
});

export const fetchFailureAppInfo = (error) => ({
  type: FETCH_APPINFO_FAILURE,
  payload: {
    error,
  },
});

export const fetchAppInfo = (appId) => {
  return (dispatch) => {
    dispatch(fetchRequestAppInfo());
    CoreApi.getPublicApp(appId)
      .then((res) => {
        dispatch(fetchSuccessAppInfo(res));
      })
      .catch((e) => {
        dispatch(
          fetchFailureAppInfo({
            description: e.description,
            text: __('Nepodarilo sa získať informácie o aplikácii'),
          }),
        );
      });
  };
};

export const fetchRequestAppSpaceUser = () => ({
  type: FETCH_APPSPACEUSER_REQUEST,
  payload: {},
});

export const fetchSuccessAppSpaceUser = (appSpace) => ({
  type: FETCH_APPSPACEUSER_SUCCESS,
  payload: {
    appSpace,
  },
});

export const fetchFailureAppSpaceUser = (error) => ({
  type: FETCH_APPSPACEUSER_FAILURE,
  payload: {
    error,
  },
});

export const fetchAppSpace = (appId, appSpace) => {
  return (dispatch) => {
    dispatch(fetchRequestAppSpaceUser());
    CoreApi.meAppInfoForAppSpace(appId, appSpace)
      .then((res) => {
        dispatch(fetchSuccessAppSpaceUser(res));
      })
      .catch(async (e) => {
        dispatch(
          fetchFailureAppSpaceUser({
            ...(e.details || {}),
            text:
              (e.details || {}).userinfo ||
              __('Nepodarilo sa získať informácie o používateľovi'),
          }),
        );
      });
  };
};
