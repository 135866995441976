import { rem } from 'polished';
import baseTheme from '@sportnet/ui/Themes/baseTheme';

const color = {
  ...baseTheme.color,
  base: '#555555',
  primary: '#5283e7',
  primaryBg: '#f1f6ff',
  danger: '#e94141',
  success: '#5bd084',
  successBg: '#e8fff0',
};

const form = {
  ...baseTheme.form,
  labelColor: color.base,
  placeholderColor: '#ccc',
};

const table = {
  ...baseTheme.table,
  headerBg: '#f9f9f9',
  evenRow: color.base,
  evenRowBg: '#f9f9f9',
  oddRow: color.base,
  oddRowBg: '#ffffff',
  activeRow: color.base,
  activeRowBg: color.primaryBg,
  highlightRow: color.base,
  highlightRowBg: color.successBg,
};

const theme = {
  ...baseTheme,
  color,
  form,
  table,
  textColor: color.base,
  inactiveColor: '#ddd',
  separatorColor: '#eee',
  baseBorderRadius: rem(2),
  largeBorderRadius: rem(4),
  fontFamily: `'Roboto', sans-serif`,
  backgroundColor: '#fff',
  shadow: `0 0 ${rem(40)} 0 rgba(0, 0, 0, 0.2)`,
};

export default theme;
