import { rem } from 'polished';
import Loader from '@sportnet/ui/Loader';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${({ width, height }) => {
    if (width && height) {
      return css`
        width: ${width}px;
        height: ${height}px;
      `;
    }
    return '';
  }};
`;

const NoImage = styled.div`
  height: 100%;
  width: 100%;
  box-shadow: inset 0 0 ${rem(0)} ${rem(2)}
    ${({ theme }) => theme.separatorColor};

  &:after,
  &:before {
    content: '';
    position: absolute;
    transform: rotate(45deg);
    left: 0;
    right: 0;
    height: ${rem(2)};
    top: 50%;
    background: ${({ theme }) => theme.separatorColor};
  }

  &:before {
    transform: rotate(-45deg);
  }

  position: relative;
  vertical-align: middle;
`;

class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      error: false,
    };

    this.handleLoad = this.handleLoad.bind(this);
    this.handleError = this.handleError.bind(this);
    this.imgEl = document.createElement('img');
  }

  UNSAFE_componentWillMount() {
    const { src } = this.props;
    this.init(src);
  }

  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      this.init(this.props.src);
    }
  }

  componentWillUnmount() {
    this.imgEl.removeEventListener('load', this.handleLoad);
    this.imgEl.removeEventListener('error', this.handleError);
  }

  init(src) {
    // remove ol' listeners
    this.imgEl.removeEventListener('load', this.handleLoad);
    this.imgEl.removeEventListener('error', this.handleError);

    if (!src) {
      return;
    }

    this.setState({ isFetching: true });

    // add new listeners
    this.imgEl.addEventListener('load', this.handleLoad);
    this.imgEl.addEventListener('error', this.handleError);

    this.imgEl.src = src;
  }

  handleLoad() {
    this.setState({
      isFetching: false,
      error: false,
    });
  }

  handleError() {
    this.setState({
      isFetching: false,
      error: true,
    });
  }

  render() {
    const { src, alt } = this.props;
    const { isFetching, error } = this.state;
    if (isFetching) {
      return (
        <Wrapper {...this.props}>
          <Loader />
        </Wrapper>
      );
    }

    if (error || !src) {
      return (
        <Wrapper {...this.props} title={alt}>
          <NoImage />
        </Wrapper>
      );
    }

    return (
      <Wrapper
        {...this.props}
        title={alt}
        style={{
          background: `url(${src}) no-repeat center center`,
        }}
      />
    );
  }
}

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

Image.defaultProps = {
  src: null,
  alt: null,
};

export default Image;
