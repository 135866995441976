import { __ } from '../../utilities/common';
import Button from '@sportnet/ui/Button';
import Input from '@sportnet/ui/Input';
import Modal, { ModalActions, ModalContent } from '@sportnet/ui/Modal';
import React, { useEffect, useRef, useState } from 'react';

const Prompt = ({
  promptTitle,
  promptValue = '',
  promptResolve = () => {},
}) => {
  const promptInputRef = useRef();
  const [promptInputValue, setPromptInputValue] = useState(promptValue);
  useEffect(() => {
    if (promptInputRef.current) {
      promptInputRef.current.focus();
    }
  }, []);
  return (
    <Modal isOpen handleClose={() => promptResolve('')}>
      <ModalContent>
        {promptTitle}
        <br />
        <br />
        <Input
          inputRef={promptInputRef}
          value={promptInputValue}
          onChange={(ev) => setPromptInputValue(ev.target.value)}
        ></Input>
      </ModalContent>
      <ModalActions>
        <Button basic onClick={() => promptResolve('')}>
          {__('Zrušiť')}
        </Button>
        <Button
          basic
          primary
          onClick={() => promptResolve(promptInputValue || '')}
        >
          {__('OK')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default Prompt;
