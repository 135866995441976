import { format, formatDistanceToNow, isValid } from 'date-fns';
import sk from 'date-fns/locale/sk';

export const number = (n, decimalPoint = ',', thousandsDelimiter = ' ') => {
  const num = Number(n);
  return num
    .toFixed(2)
    .replace(/\./g, '&')
    .replace(/\B(?=(\d{3})+(?!\d))/g, thousandsDelimiter)
    .replace(/&/g, decimalPoint);
};

export const isoDate2FormattedDate = (isoDate) => {
  if (!isValid(new Date(isoDate))) {
    return null;
  }
  return format(new Date(isoDate), 'd. M. yyyy, H:mm');
};

export const isoDate2Distance = (isoDate) => {
  if (!isValid(new Date(isoDate))) {
    return null;
  }
  return formatDistanceToNow(new Date(isoDate), { locale: sk });
};

export const isPicture = (mimeType) => {
  switch (mimeType) {
    case 'image/jpeg':
    case 'image/png':
    case 'image/tiff':
    case 'image/svg+xml':
      return true;
    default:
      return false;
  }
};

export const mimeType2type = (mimeType) => {
  switch (mimeType) {
    case 'application/pdf':
      return 'PDF';
    case 'application/rtf':
      return 'RTF';
    case 'image/jpeg':
      return 'JPG';
    case 'image/png':
      return 'PNG';
    case 'image/svg+xml':
      return 'SVG';
    case 'image/tiff':
      return 'TIFF';
    case 'video/mp4':
      return 'MP4';
    case 'text/plain':
      return 'TXT';
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
    case 'application/vnd.ms-word.document.macroEnabled.12':
    case 'application/vnd.ms-word.template.macroEnabled.12':
      return 'Word';
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
    case 'application/vnd.ms-excel.sheet.macroEnabled.12':
    case 'application/vnd.ms-excel.template.macroEnabled.12':
    case 'application/vnd.ms-excel.addin.macroEnabled.12':
    case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
      return 'Excel';
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.openxmlformats-officedocument.presentationml.template':
    case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
      return 'PPT';
    case 'application/vnd.ms-powerpoint.addin.macroEnabled.12':
    case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
    case 'application/vnd.ms-powerpoint.template.macroEnabled.12':
    case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
    case 'application/vnd.ms-access':
    default:
      return mimeType;
  }
};
